import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { components } from "../../generated/apiTypes";
import {
  addBlockToMatrix,
  createFacility,
  deleteBlockById, deleteConnectionById,
  deleteEquipmentById, deleteFacilityById,
  deleteMatrixBlockById, deleteVersionById, updateFacilityById,
} from "../../services/apiRequests";
import { deleteBlock } from "../../store/blocksSlice";
import { selectShowDialog, setShowDialog } from "../../store/commonSlice";
import {
  deleteConnection,
  deleteConnectionsBlock,
  resetConnectionsSliceState,
} from "../../store/connectionsSlice";
import { deleteEquipment } from "../../store/equipmentSlice";
import {
  deleteFacility,
  selectFacilities,
  setEditFacilityName,
  setFacilities,
} from "../../store/facilitiesSlice";
import {deleteVersion, resetFacilitySliceState, selectFacility, setVersion} from "../../store/facilitySlice";
import {
  deleteMatrixBlock, resetMatrixSliceState,
  selectMatrix,
  selectMatrixBlocks,
  setMatrixBlocks, setSelectedMatrixBlock,
  setSelectedMatrixBlockId,
  setSelectedMatrixBlockIndex,
  setSelectedMatrixBlockInstance,
} from "../../store/matrixSlice";
import ChoiceWindow from "./ChoiceWindow";
import DeleteConfirmationWindow from "./DeleteConfirmationWindow";
import ErrorWindow from "./ErrorWindow";

const DialogWindow: React.FC = () => {
  const dispatch = useDispatch();
  const showDialog = useSelector(selectShowDialog);
  const facility = useSelector(selectFacility);
  const matrix = useSelector(selectMatrix);
  const matrixBlocks = useSelector(selectMatrixBlocks);
  const facilities = useSelector(selectFacilities);
  const navigate = useNavigate();
  const [inProgress, setInProgress] = React.useState<boolean>(false);

  const closeDialog = () => {
    dispatch(
      setShowDialog({
        objectType: undefined,
        dialogType: undefined,
        objectName: undefined,
        details: undefined,
      })
    );
  };

  const deleteObject = async () => {
    switch (showDialog?.objectType) {
      case "FACILITY": {
        try {
          setInProgress(true);
          await deleteFacilityById(showDialog?.objectId as string);
          const id = parseInt(showDialog?.objectId as string);
          if (id > -1) {
            dispatch(deleteFacility(id));
            dispatch(resetFacilitySliceState());
            dispatch(resetMatrixSliceState());
          }
          navigate(`/`);
        } catch (e) {
          //
        }
        setInProgress(false);
        closeDialog();
        break;
      }
      case "BLOCK": {
        try {
          setInProgress(true);
          await deleteBlockById(showDialog?.objectId as string);
          const id = parseInt(showDialog?.objectId as string);
          if (id > -1) {
            dispatch(deleteBlock(id));
          }
        } catch (e) {
          //
        }
        setInProgress(false);
        closeDialog();
        break;
      }
      case "EQUIPMENT_ROW": {
        try {
          setInProgress(true);
          await deleteEquipmentById(showDialog?.objectId as string);
          const id = parseInt(showDialog?.objectId as string);
          if (id > -1) {
            dispatch(deleteEquipment(id));
          }
        } catch (e) {
          //
        }
        setInProgress(false);
        closeDialog();
        break;
      }
      case "MATRIX_BLOCK": {
        try {
          setInProgress(true);
          await deleteMatrixBlockById(showDialog?.objectId as string);
          const id = parseInt(showDialog?.objectId as string);
          if (id > -1) {
            dispatch(deleteMatrixBlock(id));
            if (matrixBlocks) {
              dispatch(setSelectedMatrixBlockId(matrixBlocks[0].id));
              dispatch(setSelectedMatrixBlockInstance(matrixBlocks[0]));
              dispatch(setSelectedMatrixBlockIndex(0));
            }
            dispatch(resetConnectionsSliceState());
          }
        } catch (e) {
          //
        }
        setInProgress(false);
        closeDialog();
        break;
      }
      case "VERSION": {
        if (showDialog?.objectId)
          try {
            setInProgress(true);
            const id = parseInt(showDialog?.objectId);
            await deleteVersionById(showDialog?.objectId as string);
            dispatch(deleteVersion(id));
          } catch (e) {
            //
          }
        setInProgress(false);
        closeDialog();
        break;
      }
      case "CONNECTION": {
        if (showDialog?.objectId)
          try {
            setInProgress(true);
            const id = parseInt(showDialog?.objectId);
            await deleteConnectionById(showDialog?.objectId as string);
            dispatch(deleteConnection(id));
            dispatch(deleteConnectionsBlock(id));
          } catch (e) {
            //
          }
        setInProgress(false);
        closeDialog();
        break;
      }
      default: {
        //closeDialog();
        break;
      }
    }
  };

  const onSetOption = async (
    id: number | components["schemas"]["FacilityCreate"]["schema_type_id"],
    name?: string
  ) => {
    switch (showDialog.objectType) {
      case "VERSION":
        {
          if (facility?.id) {
            try {
              setInProgress(true);
              await updateFacilityById(facility?.id.toString(), {
                active_version_id: id as number,
              });
              const index = facility?.versions?.findIndex(
                (item) => item.id === id
              );
              if (index !== undefined && index > -1) {
                dispatch(resetConnectionsSliceState());
                dispatch(setVersion(facility?.versions[index]));
              }
            } catch (e) {
              //
            }
            setInProgress(false);
          }
        }
        break;
      case "BLOCK":
        {
          if (matrix?.id) {
            try {
              setInProgress(true);
              const response = await addBlockToMatrix(matrix?.id.toString(), {
                block_template_id: id as number,
              });
              if (response.data && matrixBlocks) {
                const index = matrixBlocks.length;
                dispatch(setMatrixBlocks([...matrixBlocks, response.data]));
                dispatch(resetConnectionsSliceState());
                dispatch(
                  setSelectedMatrixBlock({
                    instance: response.data,
                    id: response.data.id,
                    index,
                  })
                );
              }
            } catch (e) {
              //
            }
            setInProgress(false);
          }
        }
        break;
      case "FACILITY":
        {
          try {
            setInProgress(true);
            const response = await createFacility({
              schema_type_id:
                id as components["schemas"]["FacilityCreate"]["schema_type_id"],
              name: name || "Новый объект",
            });
            if (response.data && facilities) {
              dispatch(setFacilities([...facilities, response.data]));
              dispatch(setEditFacilityName(true));
              navigate(`/facility=${response.data.id}`);
            }
          } catch (e) {
            //
          }
          setInProgress(false);
        }
        break;
      default:
        break;
    }
    closeDialog();
  };

  if (showDialog?.dialogType === "DELETE_CONFIRM")
    return (
      <DeleteConfirmationWindow
        closeWindow={closeDialog}
        deleteObject={deleteObject}
        inProgress={inProgress}
      />
    );
  if (showDialog?.dialogType === "ERROR")
    return <ErrorWindow closeWindow={closeDialog} />;
  if (showDialog?.dialogType === "CHOICE")
    return (
      <ChoiceWindow
        closeWindow={closeDialog}
        setOption={onSetOption}
        inProgress={inProgress}
      />
    );
  return <></>;
};

export default DialogWindow;
