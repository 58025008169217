import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { components } from "../generated/apiTypes";
import { extractObjectById } from "../utils/object";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { ArrayElement, IBlocksSlice } from "./types";

const blocksInitialState = initialState as IBlocksSlice;

export const blocksSlice = createSlice({
  name: "blocks",
  initialState: {
    selectedBlock: blocksInitialState.selectedBlock,
    blocks: blocksInitialState.blocks,
  },
  reducers: {
    setSelectedBlockId: (
      state,
      action: PayloadAction<IBlocksSlice["selectedBlock"]["id"]>
    ) => {
      state.selectedBlock.id = action.payload;
    },
    setSelectedBlockInstance: (
      state,
      action: PayloadAction<IBlocksSlice["selectedBlock"]["instance"]>
    ) => {
      state.selectedBlock.instance = action.payload;
    },
    setSelectedBlock: (
      state,
      action: PayloadAction<IBlocksSlice["selectedBlock"]>
    ) => {
      state.selectedBlock = action.payload;
    },
    setBlocks: (state, action: PayloadAction<IBlocksSlice["blocks"]>) => {
      state.blocks = action.payload;
    },
    updateBlock: (
      state,
      action: PayloadAction<components["schemas"]["Block"]>
    ) => {
      const index = state.blocks?.findIndex(
        ({ id }) => action.payload.id === id
      );
      if (index !== undefined && index > -1) {
        state.blocks = update(state.blocks, {
          [index]: { $set: action.payload },
        });
      }
    },
    deleteBlock: (
      state,
      action: PayloadAction<components["schemas"]["Block"]["id"]>
    ) => {
      const index = state.blocks?.findIndex(({ id }) => action.payload === id);
      if (index !== undefined && index > -1) {
        state.blocks = update(state.blocks, { $splice: [[index, 1]] });
      }
    },
    resetBlocksSliceState: (state) => {
      state.selectedBlock = blocksInitialState.selectedBlock;
      state.blocks = blocksInitialState.blocks;
    },
  },
});

export const {
  resetBlocksSliceState,
  setSelectedBlock,
  setSelectedBlockId,
  setSelectedBlockInstance,
  setBlocks,
  updateBlock,
  deleteBlock,
} = blocksSlice.actions;

type SelectBlockById = {
  [key: number]: ArrayElement<IBlocksSlice["blocks"]>;
};

const selectSelf = (state: RootState) => state.blocks;

export const selectSelectedBlock = createSelector(
  selectSelf,
  (blocks) => blocks.selectedBlock
);

export const selectBlocks = createSelector(
  selectSelf,
  (blocks) => blocks.blocks
);

export const selectBlockById = createSelector(selectSelf, (blocks) =>
  blocks.blocks?.reduce<SelectBlockById>(extractObjectById, {})
);

export default blocksSlice.reducer;
