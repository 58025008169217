import * as React from "react";

import classNames from "classnames";

import { components } from "../../generated/apiTypes";
import Hint from "../../pages/Blocks/components/ConnectionPoints/Hint";

import "./style.scss";

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  onEnter?(): void;
  onBlur?(): void;
  containerClassName?: string;
  focused?: boolean;
  hints?: components["schemas"]["Hint"][];
  cpId?: number;
  param?:
    | "type"
    | "temperature"
    | "pressure"
    | "molar_flow"
    | "temperature_gidrat"
    | "temperature_parafin";
  matrix?: boolean;
}

const Input: React.FC<IInput> = ({
  label,
  error,
  onEnter,
  onBlur,
  containerClassName,
  focused,
  hints,
  cpId,
  param,
  matrix,
  ...props
}) => {
  const ref = React.useRef<HTMLInputElement | null>(null);
  const menuRef = React.useRef<HTMLInputElement | null>(null);
  const [isFocus, setIsFocus] = React.useState<boolean>(false);

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (["Enter", "Escape"].includes(e.key)) {
      ref?.current?.blur();
      if (["Enter"].includes(e.key) && onEnter) {
        onEnter();
      }
    }
    return false;
  };

  React.useEffect(() => {
    if (focused) ref?.current?.focus();
  }, [focused]);

  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        menuRef?.current &&
        ref?.current &&
        !menuRef?.current?.contains(event.target) &&
        !ref?.current?.contains(event.target)
      ) {
        setIsFocus(false);
        if (onBlur) onBlur();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.value, isFocus]);

  const onFocusHandler = () => {
    setIsFocus(true);
  };

  const onBlurHandler = () => {
    if (!hints || hints.length === 0) {
      setIsFocus(false);
      if (onBlur) onBlur();
    }
  };

  return (
    <div className="input__container-wrapper">
      {label && <span className="input-label">{label}</span>}
      <div
        className={classNames(
          "input__container",
          containerClassName,
          { error },
          props.disabled && "disabled",
          isFocus && "focus"
        )}
      >
        <input
          ref={ref}
          className={classNames("input", { error })}
          {...props}
          onKeyDown={onKeyDownHandler}
          onBlur={onBlurHandler}
          onFocus={onFocusHandler}
        />
      </div>
      {isFocus && hints && cpId && param && hints?.length > 0 && (
        <div className="hints__container popup-menu" ref={menuRef}>
          {hints?.map((item) => (
            <Hint
              hint={item}
              param={param}
              blurElement={() => setIsFocus(false)}
              cpId={cpId}
              matrix={matrix}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Input;
