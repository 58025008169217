import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import update from "immutability-helper";

import IconCopy from "../../../images/icn-copy";
import IconPoints from "../../../images/icn-points";
import { updateVersionById } from "../../../services/apiRequests";
import { setShowDialog } from "../../../store/commonSlice";
import {
  selectFacility,
  selectVersion,
  setFacility,
  setVersion,
} from "../../../store/facilitySlice";
import Input from "../../Input";
import PopupMenuLayout from "../../PopupMenuLayout/popupMenuLayout";
import RadioButton from "../../RadioButton";

interface IVersionOptionProps {
  setValue(id: string): void;
  optionId?: number;
  id: number;
  name: string;
  onCopyClick(id?: number): void;
  singleVersion?: boolean;
  scrollableRef: React.RefObject<any>;
}

const VersionOption: React.FC<IVersionOptionProps> = ({
  setValue,
  optionId,
  id,
  name,
  onCopyClick,
  singleVersion,
  scrollableRef,
}) => {
  const dispatch = useDispatch();
  const facility = useSelector(selectFacility);
  const version = useSelector(selectVersion);
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);
  const [renameMode, setRenameMode] = React.useState<boolean>(false);
  const [copiedIsShown, setCopiedIsShown] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (activeMenu && menuRef?.current) {
      if (scrollableRef?.current?.clientHeight) {
        const bounding = menuRef.current.getBoundingClientRect();
        const scrollableBounding =
          scrollableRef.current.getBoundingClientRect();
        if (
          bounding.bottom >
          scrollableBounding.top + scrollableRef?.current?.clientHeight
        ) {
          menuRef.current.scrollIntoView();
        }
      }
    }
  }, [activeMenu]);

  const closeMenu = () => {
    setActiveMenu(false);
  };

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const onDeleteClick = () => {
    dispatch(
      setShowDialog({
        dialogType: "DELETE_CONFIRM",
        objectType: "VERSION",
        objectId: id.toString(),
        objectName: name,
      })
    );
  };

  const onRenameClick = () => {
    setRenameMode(true);
    closeMenu();
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (facility) {
      const versionIx = facility.versions.findIndex((item) => item.id === id);
      if (versionIx !== undefined && versionIx > -1) {
        const facilityToUpdate = update(facility, {
          versions: {
            [versionIx]: {
              $set: {
                ...facility?.versions[versionIx],
                name: e.target.value,
              },
            },
          },
        });
        dispatch(setFacility(facilityToUpdate));
        if (version?.id === id)
          dispatch(
            setVersion({
              ...facility?.versions[versionIx],
              name: e.target.value,
            })
          );
      }
    }
  };

  const onBlurHandler = async () => {
    setRenameMode(false);
    const versionIx = facility?.versions.findIndex((item) => item.id === id);
    if (versionIx !== undefined && versionIx > -1) {
      await updateVersionById(id.toString(), {
        name: facility?.versions[versionIx].name,
      });
    }
  };

  const onCopyToClipboardClick = () => {
    navigator.clipboard.writeText(id.toString());
    setCopiedIsShown(true);
    setTimeout(() => {
      setCopiedIsShown(false);
    }, 350);
  }

  return (
    <div className="option__container version-option-container">
      <div className="option__radio-btn-wrapper">
        <RadioButton
          id={`version-radio-${id}`}
          value={id.toString()}
          label={renameMode ? "" : name}
          checked={optionId === id}
          setValue={setValue}
        />
        {renameMode && (
          <Input
            value={name}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            focused={true}
          />
        )}
      </div>
      <div className="option__option-details">
        <div className="option__id-wrapper">
          <div className="option__id">{`id: ${id}`}</div>
          <button className="option__id-btn" onClick={onCopyToClipboardClick}>
            <IconCopy />
            {copiedIsShown && (
              <div className="option__copied-message">
                Скопировано&nbsp;в&nbsp;буфер
              </div>
            )}
          </button>
        </div>
        <div className="option__menu-btn-wrapper">
          <button
            className="option__id-btn"
            ref={controlRef}
            onClick={toggleMenu}
          >
            <IconPoints />
          </button>
          {activeMenu && (
            <PopupMenuLayout
              className="layout__popup-menu option__popup-menu"
              closeMenu={closeMenu}
              controlRef={controlRef}
            >
              <div ref={menuRef}>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={onRenameClick}
                >
                  Переименовать
                </button>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={() => {
                    onCopyClick(id);
                    closeMenu();
                  }}
                >
                  Создать копию
                </button>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={onDeleteClick}
                  disabled={singleVersion || id === version?.id}
                >
                  Удалить
                </button>
              </div>
            </PopupMenuLayout>
          )}
        </div>
      </div>
    </div>
  );
};

export default VersionOption;
