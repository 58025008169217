
import { AxiosResponse } from "axios";

import { api } from "../api";
import { components } from '../generated/apiTypes';

export const signInWithPassword = (formData: {
  username: string;
  password: string;
}): Promise<AxiosResponse<components["schemas"]["Token"]>> => {
  return api.post(
    `/ontology/token`,
    new URLSearchParams({
      username: formData.username,
      password: formData.password,
    }),
    { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
  );
};

export const createBlock = (): Promise<
  AxiosResponse<components["schemas"]["Block"]>
> => {
  return api.post(`/ontology/blocks`);
};

export const getBlocks = (): Promise<
  AxiosResponse<components["schemas"]["Block"][]>
> => {
  return api.get(`/ontology/blocks`);
};

export const getBlockById = (
  id: string
): Promise<AxiosResponse<components["schemas"]["Block"]>> => {
  return api.get(`/ontology/blocks/${id}`);
};

export const updateBlockById = (
  id: string,
  data: components["schemas"]["BlockUpdate"]
): Promise<AxiosResponse<components["schemas"]["Block"]>> => {
  return api.patch(`/ontology/blocks/${id}`, data);
};

export const deleteBlockById = (
  id: string
): Promise<AxiosResponse> => {
  return api.delete(`/ontology/blocks/${id}`);
};

export const getOptions = (): Promise<
  AxiosResponse<components["schemas"]["DropDowns"]>
> => {
  return api.get(`/ontology/blocks/dropdowns`);
};

export const uploadSvg = (
  id: string,
  file: FormData
): Promise<
  AxiosResponse<components["schemas"]["SvgConnectionPointsCreationResponse"]>
> => {
  return api.post(`/ontology/blocks/${id}/svg_connection_points`, file, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const updateInConnectionPointById = (
  blockId: string,
  cpId: string,
  data: components["schemas"]["syseng_web__schemas__blockbase__InputConnectionPointUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(
    `/ontology/blocks/${blockId}/input_connection_points/${cpId}`,
    data
  );
};

export const updateOutConnectionPointById = (
  blockId: string,
  cpId: string,
  data: components["schemas"]["syseng_web__schemas__blockbase__OutConnectionPointUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(
    `/ontology/blocks/${blockId}/out_connection_points/${cpId}`,
    data
  );
};

export const getEquipmentClasses = (): Promise<
  AxiosResponse<components["schemas"]["EquipmentClassGroup"][]>
> => {
  return api.get(`/ontology/equipment/classes`);
};

export const getFacilities = (): Promise<
  AxiosResponse<components["schemas"]["MainFacilityShort"][]>
> => {
  return api.get(`/ontology/facilities/items`);
};

export const updateFacilityById = (
  id: string,
  data: components["schemas"]["FacilityUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(`/ontology/facilities/items/${id}`, data);
};

export const getFacilityById = (
  id: string
): Promise<AxiosResponse<components["schemas"]["MainFacility"]>> => {
  return api.get(`/ontology/facilities/items/${id}`);
};

export const getBlocksByMatrixId = (
  id: string
): Promise<AxiosResponse<components["schemas"]["Block"][]>> => {
  return api.get(`/ontology/facilities/connection-matrices/${id}/blocks`);
};

export const copyVersion = (
  id: string,
  data: components["schemas"]["FacilityVersion"]
): Promise<AxiosResponse<components["schemas"]["FacilityVersion"]>> => {
  return api.post(`/ontology/facilities/versions/${id}/copy`, data);
};

export const addBlockToMatrix = (
  matrixId: string,
  data: components["schemas"]["FacilityBlockAddition"]
): Promise<AxiosResponse<components["schemas"]["Block"]>> => {
  return api.post(
    `/ontology/facilities/connection-matrices/${matrixId}/blocks`,
    data
  );
};

export const getEquipmentByClassId = (
  classId?: string
): Promise<AxiosResponse<components["schemas"]["EquipmentItemParams"][]>> => {
  if (classId) {
    return api.get(`/ontology/equipment`, { params: classId });
  }
  return api.get(`/ontology/equipment`);
};

export const getTableHeads = (): Promise<
  AxiosResponse<components["schemas"]["EquipmentTableView"][]>
> => {
  return api.get(`/ontology/equipment/table-view`);
};

export const createFacility = (
  data: components["schemas"]["FacilityCreate"]
): Promise<AxiosResponse<components["schemas"]["MainFacilityShort"]>> => {
  return api.post(`/ontology/facilities/items`, data);
};

export const getConnectionsByCpId = (data: {
  inputCpId?: string;
  outCpId?: string;
}): Promise<AxiosResponse<components["schemas"]["Connection"][]>> => {
  if (data.inputCpId) {
    return api.get(`/ontology/facilities/connections`, {
      params: { input_connection_point_id: data.inputCpId },
    });
  } else if (data.outCpId) {
    return api.get(`/ontology/facilities/connections`, {
      params: { out_connection_point_id: data.outCpId },
    });
  }
  return api.get(`/ontology/facilities/connections`);
};

export const getFacilityTemplates = (): Promise<
  AxiosResponse<components["schemas"]["SchemaType"][]>
> => {
  return api.get(`/ontology/facilities/templates`);
};

export const deleteMatrixBlockById = (id: string): Promise<AxiosResponse> => {
  return api.delete(`/ontology/facilities/connection-matrices/blocks/${id}`);
};

export const createEquipmentByClassId = (
  class_id: string
): Promise<AxiosResponse<components["schemas"]["EquipmentItemParams"]>> => {
  return api.post(`/ontology/equipment`, {}, { params: { class_id } });
};

export const deleteEquipmentById = (equipmentId: string): Promise<AxiosResponse> => {
  return api.delete(`/ontology/equipment/${equipmentId}`);
};

export const updateEquipmentById = (
  equipmentId: string,
  columnKey: string,
  value: string
): Promise<AxiosResponse> => {
  return api.patch(`/ontology/equipment/${equipmentId}/params/${columnKey}`, {
    value,
  });
};

export const createEquipmentById = (
  equipmentId: string,
  columnKey: string
): Promise<AxiosResponse> => {
  return api.post(`/ontology/equipment/${equipmentId}/params/${columnKey}`);
};

export const createConnectionById = (
  data: components["schemas"]["ConnectionCreate"]
): Promise<AxiosResponse<components["schemas"]["Connection"]>> => {
  return api.post(`/ontology/facilities/connections`, data);
};

export const updateConnectionById = (
  id: number,
  data: components["schemas"]["ConnectionUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(`/ontology/facilities/connections/${id}`, data);
};

export const updateConnectionsByCpId = (
  id: number,
  data: components["schemas"]["ConnectionOrder"][]
): Promise<AxiosResponse> => {
  return api.patch(
    `/ontology/facilities/out_connection_points/${id}/connections`,
    data
  );
};

export const deleteVersionById = (id: string): Promise<AxiosResponse> => {
  return api.delete(`/ontology/facilities/versions/${id}`);
};

export const deleteFacilityById = (id: string): Promise<AxiosResponse> => {
  return api.delete(`/ontology/facilities/items/${id}`);
};

export const updateVersionById = (
  id: string,
  data: components["schemas"]["FacilityVersionUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(`/ontology/facilities/versions/${id}`, data);
};

export const getAvailableBlocksToConnectByCpId = (
  id: string
): Promise<AxiosResponse<components["schemas"]["AvailableBlock"][]>> => {
  return api.get(
    `/ontology/facilities/out_connection_points/${id}/available-blocks`
  );
};

export const updateMatrixBlocks = (
  matrixId: string,
  data: components["schemas"]["FacilityBlockOrder"][]
): Promise<AxiosResponse> => {
  return api.patch(
    `/ontology/facilities/connection-matrices/${matrixId}/blocks`,
    data
  );
};

export const updateMatrixBlockInputConnectionPointById = (
  cpId: string,
  data: components["schemas"]["syseng_web__schemas__facility__InputConnectionPointUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(
    `/ontology/facilities/input_connection_points/${cpId}`,
    data
  );
};

export const updateMatrixBlockOutConnectionPointById = (
  cpId: string,
  data: components["schemas"]["syseng_web__schemas__facility__OutConnectionPointUpdate"]
): Promise<AxiosResponse> => {
  return api.patch(`/ontology/facilities/out_connection_points/${cpId}`, data);
};

export const deleteConnectionById = (id: string): Promise<AxiosResponse> => {
  return api.delete(`/ontology/facilities/connections/${id}`);
};

export const getMethods = (): Promise<
  AxiosResponse<components["schemas"]["HintShort"][]>
> => {
  return api.get(`/ontology/wiki/hints`);
};

export const getMethodById = (
  id: string
): Promise<AxiosResponse<components["schemas"]["Hint"]>> => {
  return api.get(`/ontology/wiki/hints/${id}`);
};

export const validateByVersionId = (
  versionId: string
): Promise<AxiosResponse<components["schemas"]["ConnectionMatrixError"][]>> => {
  return api.post(`/ontology/facilities/versions/${versionId}/errors`);
};
