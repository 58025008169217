import React from "react";
import { useSelector } from "react-redux";

import SearchInput from "../../../components/SearchInput";
import { selectEquipmentClasses } from "../../../store/equipmentSlice";
import useEquipmentClasses from "../hooks/use-equipment-classes";
import ToggleList from "./ToggleList";

const EquipmentTree: React.FC = () => {
  useEquipmentClasses();
  const equipmentClasses = useSelector(selectEquipmentClasses);
  const [searchInputValue, setSearchInputValue] = React.useState<string>("");

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
  };

  return (
    <div className="blocks-tree__container">
      <div>
        {/*<div className="blocks-tree__search-wrapper">
          <SearchInput
            onInput={onChangeSearchInputValue}
            value={searchInputValue}
            placeholder="Оборудование"
            containerClassName="blocks-tree__search-input-container"
          />
        </div>*/}
        <div className="blocks-tree__blocks">
          {equipmentClasses?.map((item) => (
            <ToggleList
              key={item.category}
              category={item.category}
              data={item.classes}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EquipmentTree;
