import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SingleValue } from "react-select";

import update from "immutability-helper";

import Dropdown from "../../../../components/Dropdown";
import { MyOptionType } from "../../../../components/Dropdown/handleStyles";
import Input from "../../../../components/Input";
import { components } from "../../../../generated/apiTypes";
import { updateOutConnectionPointById } from "../../../../services/apiRequests";
import {
  selectSelectedBlock,
  setSelectedBlockInstance,
  updateBlock,
} from "../../../../store/blocksSlice";
import {
  selectHints,
  selectTypesOptions,
} from "../../../../store/optionsSlice";

import "./style.scss";

interface InputPoint {
  connectionPoint: components["schemas"]["OutConnectionPoint"];
}

const OutputPoint: React.FC<InputPoint> = ({ connectionPoint }) => {
  const dispatch = useDispatch();
  const selectedBlock = useSelector(selectSelectedBlock);
  const typesOptions = useSelector(selectTypesOptions);
  const hints = useSelector(selectHints);
  const [valueError, setValueError] = React.useState<string[]>([]);

  const onOptionChange = async (
    option: SingleValue<MyOptionType>,
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (option?.value && selectedBlock.instance && selectedBlock.id) {
      try {
        const index = selectedBlock.instance?.out_connection_points.findIndex(
          ({ id }) => connectionPoint.id === id
        );
        if (index !== undefined && index > -1) {
          const pointsToUpdate = update(
            selectedBlock.instance?.out_connection_points,
            {
              [index]: {
                $set: {
                  ...selectedBlock.instance?.out_connection_points[index],
                  [param]: option?.value,
                },
              },
            }
          );
          const objToUpdate = {
            ...selectedBlock.instance,
            out_connection_points: pointsToUpdate,
          };
          dispatch(setSelectedBlockInstance(objToUpdate));
          await updateOutConnectionPointById(
            selectedBlock.id.toString(),
            connectionPoint.id.toString(),
            { [param]: option?.value }
          );
          dispatch(updateBlock(objToUpdate));
        }
      } catch (e) {
        //
      }
    }
  };

  const onChangeValue = (
    e: ChangeEvent<HTMLInputElement>,
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (valueError.includes(param)) {
      const ixToDelete = valueError.findIndex((item) => item === param);
      if (ixToDelete !== undefined && ixToDelete > -1) {
        const errors = update(valueError, {
          $splice: [[ixToDelete, 1]],
        });
        setValueError(errors);
      }
    }
    if (selectedBlock.instance && selectedBlock.id) {
      try {
        const index = selectedBlock.instance?.out_connection_points.findIndex(
          ({ id }) => connectionPoint.id === id
        );
        if (index !== undefined && index > -1) {
          const pointsToUpdate = update(
            selectedBlock.instance?.out_connection_points,
            {
              [index]: {
                $set: {
                  ...selectedBlock.instance?.out_connection_points[index],
                  [param]: e.target.value,
                },
              },
            }
          );
          const objToUpdate = {
            ...selectedBlock.instance,
            out_connection_points: pointsToUpdate,
          };
          dispatch(setSelectedBlockInstance(objToUpdate));
        }
      } catch (e) {
        //
      }
    }
  };

  const saveValue = async (
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (selectedBlock.id && selectedBlock.instance) {
      try {
        const index = selectedBlock.instance?.out_connection_points.findIndex(
          ({ id }) => connectionPoint.id === id
        );
        if (index !== undefined && index > -1) {
          await updateOutConnectionPointById(
            selectedBlock.id.toString(),
            connectionPoint.id.toString(),
            {
              [param]:
                selectedBlock.instance?.out_connection_points[index][param],
            }
          );
          dispatch(updateBlock(selectedBlock.instance));
        }
      } catch (e) {
        setValueError([...valueError, param]);
      }
    }
  };

  return (
    <div key={connectionPoint?.id} className="connection-points__point output">
      <div className="connection-points__sub-title">
        {connectionPoint?.name}
      </div>
      <div className="block-parameters-row">
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Тип"
            options={typesOptions}
            value={typesOptions?.find(
              (item) => item.value === connectionPoint?.type
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "type")}
          />
        </div>
        <div className="block-parameters-element">
          <Input
            placeholder="0.0"
            label="Температура, К"
            value={connectionPoint?.temperature}
            onChange={(e) => onChangeValue(e, "temperature")}
            onBlur={() => saveValue("temperature")}
            error={valueError.includes("temperature")}
            hints={hints.filter((hint) =>
              hint.name.includes(
                selectedBlock.instance?.out_connection_points.find(
                  (cp) => cp.id === connectionPoint.id
                )?.temperature as string
              )
            )}
            cpId={connectionPoint.id}
            param={"temperature"}
          />
        </div>
      </div>
      <div className="block-parameters-row">
        <div className="block-parameters-element">
          <Input
            placeholder="0.0"
            label="Давление, Pa"
            value={connectionPoint?.pressure}
            onChange={(e) => onChangeValue(e, "pressure")}
            onBlur={() => saveValue("pressure")}
            error={valueError.includes("pressure")}
            hints={hints.filter((hint) =>
              hint.name.includes(
                selectedBlock.instance?.out_connection_points.find(
                  (cp) => cp.id === connectionPoint.id
                )?.pressure as string
              )
            )}
            cpId={connectionPoint.id}
            param={"pressure"}
          />
        </div>
        <div className="block-parameters-element">
          <Input
            placeholder="0.0"
            label="Производительность, моль"
            value={connectionPoint?.molar_flow}
            onChange={(e) => onChangeValue(e, "molar_flow")}
            onBlur={() => saveValue("molar_flow")}
            error={valueError.includes("molar_flow")}
            hints={hints.filter((hint) =>
              hint.name.includes(
                selectedBlock.instance?.out_connection_points.find(
                  (cp) => cp.id === connectionPoint.id
                )?.molar_flow as string
              )
            )}
            cpId={connectionPoint.id}
            param={"molar_flow"}
          />
        </div>
      </div>
      <div className="block-parameters-row">
        <div className="block-parameters-element">
          <Input
            placeholder="0.0"
            label="Температура гидратообразования, К"
            value={connectionPoint?.temperature_gidrat}
            onChange={(e) => onChangeValue(e, "temperature_gidrat")}
            onBlur={() => saveValue("temperature_gidrat")}
            error={valueError.includes("temperature_gidrat")}
            hints={hints.filter((hint) =>
              hint.name.includes(
                selectedBlock.instance?.out_connection_points.find(
                  (cp) => cp.id === connectionPoint.id
                )?.temperature_gidrat as string
              )
            )}
            cpId={connectionPoint.id}
            param={"temperature_gidrat"}
          />
        </div>
        <div className="block-parameters-element">
          <Input
            placeholder="0.0"
            label="Температура парафинообразования, К"
            value={connectionPoint?.temperature_parafin}
            onChange={(e) => onChangeValue(e, "temperature_parafin")}
            onBlur={() => saveValue("temperature_parafin")}
            error={valueError.includes("temperature_parafin")}
            hints={hints.filter((hint) =>
              hint.name.includes(
                selectedBlock.instance?.out_connection_points.find(
                  (cp) => cp.id === connectionPoint.id
                )?.temperature_parafin as string
              )
            )}
            cpId={connectionPoint.id}
            param={"temperature_parafin"}
          />
        </div>
      </div>
    </div>
  );
};

export default OutputPoint;
