import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={14}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.41 7l4.3-4.29a1.004 1.004 0 10-1.42-1.42L7 5.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42L5.59 7l-4.3 4.29a1 1 0 000 1.42.998.998 0 001.42 0L7 8.41l4.29 4.3a.997.997 0 001.095.219.998.998 0 00.325-.22 1 1 0 000-1.42L8.41 7z"
        fill="#999"
      />
    </svg>
  );
};

export default Icon;
