import * as React from "react";
import { SVGProps } from "react";

const Icon = (props: SVGProps<any>) => {
  return (
    <svg
      width={8}
      height={6}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.683076 1.94194L3.68308 4.94194C3.92715 5.18602 4.32288 5.18602 4.56696 4.94194L7.56696 1.94194C7.74571 1.76319 7.79918 1.49437 7.70244 1.26082C7.6057 1.02728 7.37781 0.875 7.12502 0.875H1.12502C0.872229 0.875 0.644332 1.02728 0.547594 1.26082C0.450855 1.49437 0.504328 1.76319 0.683076 1.94194Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

export default Icon;
