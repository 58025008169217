import React from "react";

export interface ITheadComponent {
  title?: string;
  component?: React.ReactNode;
}

export interface ITheadFormatted {
  [key: string]: ITheadComponent;
}

export interface IThead {
  key: string;
  name: string;
}

export const formattedTableHead: ITheadFormatted = {
  /*["producer"]: {
    title: "Производитель",
  },
  ["gas_capacity__mln_m3_per_day"]: {
    component: (
      <div>
        Производительность по газу, (млн м<sup>3</sup>/сут)
      </div>
    ),
  },
  ["pressure"]: {
    title: "Расчетное давление, МПа",
  },
  ["temperature"]: {
    title: "Температура рабочей среды (макс.), °С",
  },*/
};

