import React from 'react';

import classNames from 'classnames';

import './style.scss';

interface IRadioButtonProps {
  value: string;
  setValue?(value: string): void;
  label: string;
  disabled?: boolean;
  id: string;
  checked?: boolean;
  children?: React.ReactNode;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  value,
  setValue,
  disabled,
  label,
  id,
  checked,
  children,
  ...inputProps
}) => {
  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (setValue) {
      setValue(e.target.value);
    }
  };

  return (
    <div className={classNames("radio__input-row", { disabled })}>
      <input
        type="radio"
        aria-checked={false}
        className="radio__input"
        value={value}
        onChange={onChangeValue}
        disabled={disabled}
        id={id}
        checked={checked}
        {...inputProps}
      />
      {children}
      <label htmlFor={id} className="radio__label">
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
