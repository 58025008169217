import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import LockIcon from "../../../../images/icn-lock";
import DownArrowIcon from "../../../../images/icn-sort-down";
import { updateMatrixBlocks } from "../../../../services/apiRequests";
import {
  resetConnectionsSliceState, selectConnectionPoint,
  selectConnections,
} from "../../../../store/connectionsSlice";
import {
  downMatrixBlock,
  selectMatrix,
  selectMatrixBlocks,
  setSelectedMatrixBlockId,
  setSelectedMatrixBlockIndex,
  setSelectedMatrixBlockInstance,
  upMatrixBlock,
} from "../../../../store/matrixSlice";
import { selectSelectedMatrixBlock } from "../../../../store/matrixSlice";

import "./style.scss";

interface IBlock {
  id: number;
  name?: string;
  index: number;
  length: number;
  locked?: boolean;
}

const Block: React.FC<IBlock> = ({ id, name, index, length, locked }) => {
  const dispatch = useDispatch();
  const selectedMatrixBlock = useSelector(selectSelectedMatrixBlock);
  const matrix = useSelector(selectMatrix);
  const connections = useSelector(selectConnections);
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const matrixBlocks = useSelector(selectMatrixBlocks);
  const [reorder, setReorder] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (matrix?.id && matrixBlocks && reorder) {
      updateMatrixBlocks(
        matrix?.id.toString(),
        matrixBlocks?.map((item) => {
          return { id: item.id };
        })
      );
      setReorder(false);
    }
  }, [matrixBlocks]);

  const onBlockClick = () => {
    if (selectedMatrixBlock?.id !== id) {
      dispatch(resetConnectionsSliceState());
      dispatch(setSelectedMatrixBlockId(id));
      dispatch(setSelectedMatrixBlockIndex(index));
      dispatch(setSelectedMatrixBlockInstance(undefined));
    }
  };

  const onUpArrowClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(upMatrixBlock(id));
    setReorder(true);
  };

  const onDownArrowClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(downMatrixBlock(id));
    setReorder(true);
  };

  const connectionOrder =
    id &&
    connections?.findIndex(
      (connection) => connection.input_connection_point?.block.id === id
    );

  return (
    <button
      key={id}
      className={classNames(
        "block__container facility-block__container",
        selectedMatrixBlock?.id === id && "selected"
      )}
      onClick={onBlockClick}
    >
      <div className="facility-block__name-container">
        {index === 0 || locked ? (
          <div className="block__lock">
            <LockIcon />
          </div>
        ) : (
          <button
            className="block__control-arrows"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className="block__control-arrow up-arrow"
              disabled={index === 1}
              onClick={onUpArrowClick}
            >
              <DownArrowIcon />
            </button>
            <button
              className="block__control-arrow"
              onClick={onDownArrowClick}
              disabled={index === length - 1}
            >
              <DownArrowIcon />
            </button>
          </button>
        )}
        <span className="block__title">{name}</span>
      </div>
      {connectionOrder !== undefined && connectionOrder > -1 && (
        <div
          className={classNames(
            "connection-row__priority-order",
            selectedConnectionPoint?.type === "IN" && "input-point-order"
          )}
        >
          {selectedConnectionPoint?.type === "OUT" ? connectionOrder + 1 : ""}
        </div>
      )}
    </button>
  );
};

export default Block;
