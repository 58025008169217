import * as React from "react";

import classnames from "classnames";

import Input from "../../components/Input";
import { FormFieldPropsType } from "./types";

const FormField: React.FC<FormFieldPropsType> = React.memo(
  ({
    type,
    placeholder,
    onChangeHandler,
    formValue,
    error,
    onEnter,
    label,
  }) => {
    const [value, setValue] = React.useState<string>("");

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
      onChangeHandler(e, formValue);
      setValue(e.currentTarget.value);
    };

    return (
      <div
        className={classnames(
          "login-page__input-container relative",
          formValue
        )}
      >
        <Input
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          error={error}
          onEnter={onEnter}
          label={label}
        />
      </div>
    );
  }
);

export default FormField;
