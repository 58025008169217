import * as React from "react";

const Icon = (props: { classname?: string }) => {
  return (
    <svg
      width={36}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classname}
      {...props}
    >
      <path
        d="M14.1517 1.55661C14.9731 0.1478 17.0269 0.147797 17.8483 1.5566L31.7109 25.3302C32.5324 26.739 31.5055 28.5 29.8625 28.5H2.13746C0.494496 28.5 -0.532361 26.739 0.289123 25.3302L14.1517 1.55661Z"
        fill="#F15050"
      />
      <rect x="14" y="6.49988" width="4" height="12" rx="2" fill="#242425" />
      <rect x="14" y="20.4999" width="4" height="4" rx="2" fill="#242425" />
    </svg>
  );
};

export default Icon;
