import React from "react";
import { useNavigate } from "react-router-dom";

import Logo from "../../../images/logo";
import Arrow from "../../../images/nav-arrow";
import Logout from "./Logout";

import "./style.scss";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="header__container">
      <div className="header__content">
        <div className="logo-text-wrapper">
          <Logo className="login-page__logo" />
          База знаний
        </div>
        <div className="header__navigation-container">
          <button
            className="header__navigation-tab"
            onClick={() => navigate("/blocks")}
          >
            База блоков
            <Arrow />
          </button>
          <button
            className="header__navigation-tab"
            onClick={() => navigate("/equipment")}
          >
            База оборудования
            <Arrow />
          </button>
          <button
            className="header__navigation-tab"
            onClick={() => navigate("/methods")}
          >
            База методик
            <Arrow />
          </button>
        </div>
        <Logout />
      </div>
    </div>
  );
};

export default Header;
