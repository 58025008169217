import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import {components} from "../generated/apiTypes";
import { extractObjectById } from "../utils/object";
import { initialState } from "./initialState";
import { RootState } from "./store";
import {ArrayElement, IMatrixSlice, IMethodSlice} from "./types";

const methodInitialState = initialState as IMethodSlice;

export const methodSlice = createSlice({
  name: "method",
  initialState: {
    selectedMethod: methodInitialState.selectedMethod,
  },
  reducers: {
    setSelectedMethodId: (
      state,
      action: PayloadAction<IMethodSlice["selectedMethod"]["id"]>
    ) => {
      state.selectedMethod.id = action.payload;
    },
    setSelectedMethodInstance: (
      state,
      action: PayloadAction<IMethodSlice["selectedMethod"]["instance"]>
    ) => {
      state.selectedMethod.instance = action.payload;
    },
    resetMethodSliceState: (state) => {
      state.selectedMethod = methodInitialState.selectedMethod;
    },
  },
});

export const {
  setSelectedMethodId,
  setSelectedMethodInstance,
  resetMethodSliceState,
} = methodSlice.actions;

const selectSelf = (state: RootState) => state.method;

export const selectSelectedMethod = createSelector(
  selectSelf,
  (method) => method.selectedMethod
);

export default methodSlice.reducer;
