import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button";
import SearchInput from "../../../../components/SearchInput";
import { components } from "../../../../generated/apiTypes";
import { createBlock } from "../../../../services/apiRequests";
import {
  selectBlocks, selectSelectedBlock,
  setBlocks, setSelectedBlock,
  setSelectedBlockId,
  setSelectedBlockInstance,
} from "../../../../store/blocksSlice";
import useBlocks from "../../hooks/use-blocks";
import Block from "./Block";

import "./style.scss";

const BlocksTree: React.FC = () => {
  const dispatch = useDispatch();
  useBlocks();
  const blocks = useSelector(selectBlocks);
  const selectedBlock = useSelector(selectSelectedBlock);

  const [searchInputValue, setSearchInputValue] = React.useState<string>("");
  const [filteredBlocks, setFilteredBlocks] = React.useState<
    components["schemas"]["Block"][]
  >([]);

  React.useEffect(() => {
    if (blocks) setFilteredBlocks(blocks);
  }, [blocks]);

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    if (blocks) {
      const filteredList = blocks.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      if (!filteredList.find((item) => item.id === selectedBlock.id)) {
        dispatch(setSelectedBlock({}));
      }
      setFilteredBlocks(filteredList);
    }
  };

  const onCreateBtnClick = async () => {
    const response = await createBlock();
    if (response?.data) {
      dispatch(setSelectedBlockId(response.data.id));
      dispatch(setSelectedBlockInstance(response?.data));
      if (blocks) dispatch(setBlocks([...blocks, response?.data]));
    }
  };

  return (
    <div className="blocks-tree__container">
      <div>
        <div className="blocks-tree__search-wrapper">
          <SearchInput
            onInput={onChangeSearchInputValue}
            value={searchInputValue}
            placeholder="Блок"
            containerClassName="blocks-tree__search-input-container"
          />
        </div>
        <div className="blocks-tree__blocks">
          {filteredBlocks &&
            filteredBlocks?.length > 0 &&
            filteredBlocks?.map((item) => (
              <Block id={item.id} name={item.name} />
            ))}
          {filteredBlocks &&
            filteredBlocks?.length === 0 &&
            !searchInputValue && (
              <div className="blocks-tree__blocks-empty-message">
                Не добавлен ни один блок
              </div>
            )}
          {filteredBlocks &&
            filteredBlocks?.length === 0 &&
            searchInputValue && (
              <div className="blocks-tree__blocks-empty-message">
                Не найдены блоки соответствующие данному запросу
              </div>
            )}
        </div>
      </div>
      <div className="blocks-tree__btn-wrapper">
        <Button className="blocks-tree__btn" onClick={onCreateBtnClick}>
          Добавить блок
        </Button>
      </div>
    </div>
  );
};

export default BlocksTree;
