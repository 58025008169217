import React from "react";
import {useDispatch, useSelector} from "react-redux";

import Button from "../../../components/Button";
import { createEquipmentByClassId } from "../../../services/apiRequests";
import {selectAllEquipment, selectSelectedClass, setAllEquipment} from "../../../store/equipmentSlice";
import Table from "./Table/table";

import "../style.scss";

const TablePanel: React.FC = () => {
  const dispatch = useDispatch();
  const selectedClass = useSelector(selectSelectedClass);
  const allEquipment = useSelector(selectAllEquipment);

  const onAddEquipmentClick = async () => {
    if (selectedClass?.id && allEquipment) {
      const response = await createEquipmentByClassId(
        selectedClass.id.toString()
      );
      if (response.data)
        dispatch(setAllEquipment([...allEquipment, response.data]));
    }
  };

  return (
    <div className="table-panel__container">
      {selectedClass?.id && (
        <>
          <Table />
          <Button
            className="table-panel__add-row-button"
            mode={"secondary"}
            onClick={onAddEquipmentClick}
          >
            Добавить строку
          </Button>
        </>
      )}
    </div>
  );
};

export default TablePanel;
