import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.93 6.012a.75.75 0 011.058-.081L8 8.512l3.012-2.581a.75.75 0 01.976 1.138l-3.5 3a.75.75 0 01-.976 0l-3.5-3a.75.75 0 01-.081-1.057z"
        fill="#999"
      />
    </svg>
  );
};

export default Icon;
