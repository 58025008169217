import * as React from "react";
import {useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";

import DeleteIcon from "../../../images/icn-delete";
import { setShowDialog } from "../../../store/commonSlice";

import "./style.scss";

interface IFacility {
  id: string;
  name: string;
  dateTime: string;
}

const Index: React.FC<IFacility> = ({ id, name, dateTime }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFacilityClick = () => {
    navigate(`/facility=${id}`);
  };

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setShowDialog({
        objectName: name,
        objectType: "FACILITY",
        dialogType: "DELETE_CONFIRM",
        objectId: id,
      })
    );
  };

  return (
    <button key={id} className="facility__container" onClick={onFacilityClick}>
      <div>
        <div className="facility__name-row">{name}</div>
        <div className="facility__details-row">{dateTime}</div>
      </div>
      <button className="facility__delete-btn" onClick={onDeleteClick}>
        <DeleteIcon />
      </button>
    </button>
  );
};

export default Index;
