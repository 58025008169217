import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SingleValue } from "react-select";

import classNames from "classnames";
import update from "immutability-helper";

import Dropdown from "../../../../components/Dropdown";
import { MyOptionType } from "../../../../components/Dropdown/handleStyles";
import { components } from "../../../../generated/apiTypes";
import IconArrow from "../../../../images/components/dropdown/indicator";
import IconToggle from "../../../../images/icn-toggle";
import { updateMatrixBlockInputConnectionPointById } from "../../../../services/apiRequests";
import {
  resetConnectionsSliceState,
  selectConnectionPoint,
  selectPanelIsShown,
  setConnectionPoint, setConnections,
  setPanelIsShown,
} from "../../../../store/connectionsSlice";
import {
  selectSelectedMatrixBlock,
  setSelectedMatrixBlockInstance,
  updateMatrixBlock
} from "../../../../store/matrixSlice";
import {
  selectGidratOptions,
  selectMolarFlowOptions,
  selectParafinOptions,
  selectPressureOptions,
  selectTemperatureOptions,
  selectTypesOptions,
} from "../../../../store/optionsSlice";
import useOptions from "../../../Blocks/hooks/use-options";

import "./style.scss";

interface IInputPointProps {
  connectionPoint: components["schemas"]["InputConnectionPoint"];
}

const InputConnectionPoint: React.FC<IInputPointProps> = ({
  connectionPoint,
}) => {
  useOptions();
  const dispatch = useDispatch();
  const gidratOptions = useSelector(selectGidratOptions);
  const parafinOptions = useSelector(selectParafinOptions);
  const temperatureOptions = useSelector(selectTemperatureOptions);
  const pressureOptions = useSelector(selectPressureOptions);
  const typesOptions = useSelector(selectTypesOptions);
  const molarFlowOptions = useSelector(selectMolarFlowOptions);
  const connectionsPanelIsShown = useSelector(selectPanelIsShown);
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const selectedMatrixBlock = useSelector(selectSelectedMatrixBlock);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const detailsRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (expanded && detailsRef?.current) {
      detailsRef.current.scrollIntoView();
    }
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleConnectionsPanel = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (
      connectionsPanelIsShown !== undefined &&
      selectedConnectionPoint?.name === connectionPoint.name &&
      connectionsPanelIsShown
    ) {
      dispatch(resetConnectionsSliceState());
    } else if (
      connectionsPanelIsShown !== undefined &&
      !connectionsPanelIsShown
    ) {
      dispatch(setPanelIsShown(true));
      dispatch(
        setConnectionPoint({
          id: connectionPoint.id,
          name: connectionPoint.name,
          type: "IN",
        })
      );
    } else if (
      connectionsPanelIsShown !== undefined &&
      connectionsPanelIsShown &&
      selectedConnectionPoint?.name !== connectionPoint.name
    ) {
      dispatch(setConnections());
      dispatch(
        setConnectionPoint({
          id: connectionPoint.id,
          name: connectionPoint.name,
          type: "IN",
        })
      );
    }

  };

  const onOptionChange = async (
    option: SingleValue<MyOptionType>,
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (option?.value && selectedMatrixBlock.instance && selectedMatrixBlock.id) {
          try {
            const index =
                selectedMatrixBlock.instance?.input_connection_points.findIndex(
                    ({ id }) => connectionPoint.id === id
                );
            if (index !== undefined && index > -1) {
              const pointsToUpdate = update(
                  selectedMatrixBlock.instance?.input_connection_points,
                  {
                    [index]: {
                      $set: {
                        ...selectedMatrixBlock.instance?.input_connection_points[index],
                        [param]: option?.value,
                      },
                    },
                  }
              );
              const objToUpdate = {
                ...selectedMatrixBlock.instance,
                input_connection_points: pointsToUpdate,
              };
              dispatch(setSelectedMatrixBlockInstance(objToUpdate));
              await updateMatrixBlockInputConnectionPointById(
                connectionPoint.id.toString(),
                { [param]: option?.value }
              );
              dispatch(updateMatrixBlock(objToUpdate));
            }
          } catch (e) {
            //
          }
        }
  };

  return (
    <div className="facility-block-details__connection-point-container">
      <button
        className="facility-block-details__connection-point-top-row"
        onClick={toggleExpanded}
      >
        <div
          className={classNames(
            "facility-block-details__connection-point-toggle-list",
            {
              expanded,
            }
          )}
        >
          <IconToggle />
          <span className="block__title">{connectionPoint.name}</span>
        </div>
        <button
          className={classNames(
            "facility-block-details__connection-point-btn",
            selectedConnectionPoint?.name === connectionPoint.name && "selected"
          )}
          onClick={toggleConnectionsPanel}
        >
          См. подкл.
          <IconArrow />
        </button>
      </button>
      {expanded && (
        <div
          ref={detailsRef}
          className="facility-block-details__connection-point-details"
        >
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Тип"
                options={typesOptions}
                value={typesOptions?.find(
                  (item) => item.value === connectionPoint?.type
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "type")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Температура, К"
                options={temperatureOptions}
                value={temperatureOptions?.find(
                  (item) => item.value === connectionPoint?.temperature
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "temperature")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
          </div>
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Давление, Pa"
                options={pressureOptions}
                value={pressureOptions?.find(
                  (item) => item.value === connectionPoint?.pressure
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "pressure")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Производительность, моль"
                options={molarFlowOptions}
                value={molarFlowOptions?.find(
                  (item) => item.value === connectionPoint?.molar_flow
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "molar_flow")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
          </div>
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Температура гидратообразования, К"
                options={gidratOptions}
                value={gidratOptions?.find(
                  (item) => item.value === connectionPoint?.temperature_gidrat
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "temperature_gidrat")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Температура парафинообразования, К"
                options={parafinOptions}
                value={parafinOptions?.find(
                  (item) => item.value === connectionPoint?.temperature_parafin
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "temperature_parafin")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputConnectionPoint;
