import * as React from "react";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import classNames from "classnames";

import ArrowIcon from "../../images/components/dropdown/indicator";
import BackIcon from "../../images/icn-back";
import IconError from "../../images/icn-error";
import MenuIcon from "../../images/icn-menu";
import {
  updateFacilityById,
  validateByVersionId,
} from "../../services/apiRequests";
import { setShowDialog } from "../../store/commonSlice";
import { selectErrors, setErrors } from "../../store/errorsSlice";
import {
  selectEditFacilityName,
  setEditFacilityName,
} from "../../store/facilitiesSlice";
import {
  selectFacility,
  selectVersion,
  setFacility,
} from "../../store/facilitySlice";
import {selectMatrix, setMatrix} from "../../store/matrixSlice";
import Input from "../Input";
import PopupMenuLayout from "../PopupMenuLayout/popupMenuLayout";

import "./style.scss";

interface ILayout {
  pageTitle?: string;
  children: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({ pageTitle, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const version = useSelector(selectVersion);
  const facility = useSelector(selectFacility);
  const errors = useSelector(selectErrors);
  const editFacilityName = useSelector(selectEditFacilityName);
  const [selectedTabIx, setSelectedTabIx] = React.useState<number>();
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const [copiedIsShown, setCopiedIsShown] = React.useState<boolean>(false);

  React.useEffect(() => {
    onTabClick(0);
  }, [version?.connection_matrices]);

  const onVersionClick = () => {
    dispatch(setShowDialog({ dialogType: "CHOICE", objectType: "VERSION" }));
  };

  const onTabClick = (index: number) => {
    setSelectedTabIx(index);
    dispatch(setMatrix(version?.connection_matrices[index]));
  };

  const onMenuClick = () => {
    if (location.pathname.includes("/facility")) {
      setActiveMenu(!activeMenu);
    } else {
      navigate("/");
    }
  };

  const closeMenu = () => {
    setActiveMenu(false);
  };

  const onDeleteClick = () => {
    dispatch(
      setShowDialog({
        objectName: facility?.name,
        objectType: "FACILITY",
        dialogType: "DELETE_CONFIRM",
        objectId: facility?.id.toString(),
      })
    );
  };

  const onRenameClick = () => {
    dispatch(setEditFacilityName(true));
  };

  const onBlurHandler = async () => {
    dispatch(setEditFacilityName(false));
    if (facility) {
      await updateFacilityById(facility.id.toString(), { name: facility.name });
    }
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (facility) {
      dispatch(setFacility({ ...facility, name: e.target.value }));
    }
  };

  const onCopyToClipboardClick = () => {
    navigator.clipboard.writeText( (version?.id as number).toString());
    setCopiedIsShown(true);
    setTimeout(() => {
      setCopiedIsShown(false);
    }, 350);
  }

  const validateSchema = async () => {
    if (version?.id) {
      try {
        const response = await validateByVersionId(version.id.toString());
        dispatch(setErrors(response.data));
      } catch (e) {
        //
      }
    }
    closeMenu();
  };

  return (
    <>
      <div className="layout__header">
        <div className="layout__left-header-content">
          <div className="layout__menu-wrapper">
            <button
              className={classNames(
                "layout__icn-back",
                activeMenu && "selected"
              )}
              onClick={onMenuClick}
              ref={controlRef}
            >
              {location.pathname.includes("/facility") ? (
                <MenuIcon />
              ) : (
                <BackIcon />
              )}
            </button>
            {activeMenu && (
              <PopupMenuLayout
                className="layout__popup-menu"
                closeMenu={closeMenu}
                controlRef={controlRef}
              >
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={() => navigate("/")}
                >
                  К списку объектов
                </button>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={onRenameClick}
                >
                  Переименовать
                </button>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={onCopyToClipboardClick}
                >
                  Скопировать id тек. версии
                  {copiedIsShown && (
                    <div className="option__copied-message menu-message">
                      Скопировано&nbsp;в&nbsp;буфер
                    </div>
                  )}
                </button>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={validateSchema}
                >
                  Проверить схему
                </button>
                <button
                  type="button"
                  className="popup-menu-item"
                  onClick={onDeleteClick}
                >
                  Удалить
                </button>
              </PopupMenuLayout>
            )}
          </div>
          <span className="layout__title">
            {location.pathname.includes("/facility") && editFacilityName ? (
              <Input
                value={pageTitle}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                focused={true}
              />
            ) : (
              pageTitle
            )}
          </span>
        </div>
        <div className="layout__tabs">
          {location.pathname.includes("/facility") &&
            version?.connection_matrices.map((item, index) => (
              <button
                className={classNames(
                  "layout__tab",
                  selectedTabIx === index && "selected"
                )}
                onClick={() => onTabClick(index)}
              >
                <div className="layout__tab-name">
                  {item.name}
                  {errors?.find((m) => m.connection_matrix_id === item.id)
                    ?.errors && <IconError />}
                </div>
              </button>
            ))}
        </div>
        <div className="layout__version-btn-wrapper">
          {location.pathname.includes("/facility") && version?.name ? (
            <button className="layout__version-btn" onClick={onVersionClick}>
              <span className="layout__version-btn-text">{version.name}</span>
              <ArrowIcon />
            </button>
          ) : (
            <div className="layout__version-btn invisible" />
          )}
        </div>
      </div>
      {children}
    </>
  );
};

export default Layout;
