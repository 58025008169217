import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {MyOptionType} from "../../../../../components/Dropdown/handleStyles";
import IconPlus from "../../../../../images/icn-plus";
import { createConnectionById } from "../../../../../services/apiRequests";
import {
  selectAvailableBlocks,
  selectConnectionPoint,
  selectConnections,
  selectConnectionsBlocks,
  setConnections,
} from "../../../../../store/connectionsSlice";
import { getBlocksOptionsFromAvailableBlocks } from "../../../../../utils/optionsFromArray";

const ControlRow: React.FC = () => {
  const dispatch = useDispatch();
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const connections = useSelector(selectConnections);
  const availableBlocks = useSelector(selectAvailableBlocks);
  const connectionsBlocks = useSelector(selectConnectionsBlocks);

  const onAddConnection = async () => {
    if (selectedConnectionPoint?.id) {
      try {
        const response = await createConnectionById({
          out_connection_point_id: selectedConnectionPoint?.id,
        });
        if (response.data && connections) {
          dispatch(setConnections([...connections, response.data]));
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <div className="labels-row__container">
      <div className="block__control-arrows connection-row__arrows invisible">
        <div className="block__control-arrow up-arrow" />
        <div className="block__control-arrow" />
      </div>
      <div className="connection-row__priority-order invisible" />
      <div className="connection-row__select-container">
        {selectedConnectionPoint?.type === "OUT" && (
          <button
            className="control-row__add-btn"
            onClick={onAddConnection}
            disabled={
              availableBlocks &&
              connections &&
              availableBlocks.length - 1 < connections.length
            }
          >
            <IconPlus />
            Добавить опцию
          </button>
        )}
      </div>
      <div className="connection-row__select-container" />
      <div className="connection-row__select-container" />
      <div className="facility__delete-btn labels-row__btn invisible" />
    </div>
  );
};

export default ControlRow;
