import React from "react";
import { useDispatch } from "react-redux";

import { setApiError, setShowDialog } from "../../../store/commonSlice";
import { resetConnectionsSliceState } from "../../../store/connectionsSlice";
import {resetErrorsSliceState} from "../../../store/errorsSlice";
import { resetFacilitySliceState } from "../../../store/facilitySlice";
import { resetMatrixSliceState } from "../../../store/matrixSlice";

const useCleanup = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(resetMatrixSliceState());
      dispatch(resetConnectionsSliceState());
      dispatch(resetFacilitySliceState());
      dispatch(resetErrorsSliceState());
      dispatch(
        setShowDialog({
          objectType: undefined,
          dialogType: undefined,
          objectName: undefined,
          details: undefined,
        })
      );
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        })
      );
    };
  }, []);
};

export default useCleanup;
