import React from "react";
import { useDispatch } from "react-redux";

import {
  getEquipmentByClassId,
  getEquipmentClasses,
} from "../../../services/apiRequests";
import {
  setAllEquipment,
  setEquipmentClasses,
} from "../../../store/equipmentSlice";

const useEquipmentClasses = () => {
  const dispatch = useDispatch();

  const fetchEquipmentClasses = async () => {
    try {
      const response = await getEquipmentClasses();
      if (response?.data) {
        dispatch(setEquipmentClasses(response.data));
      }
    } catch (e) {
      //
    }
  };

  const fetchAllEquipment = async () => {
    try {
      const response = await getEquipmentByClassId();
      if (response?.data) {
        dispatch(setAllEquipment(response.data));
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchEquipmentClasses();
    fetchAllEquipment();
  }, []);

  return;
};

export default useEquipmentClasses;
