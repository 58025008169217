import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { components } from "../generated/apiTypes";
import { extractObjectById } from "../utils/object";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { ArrayElement, IBlocksSlice, IMethodsSlice } from "./types";

const methodsInitialState = initialState as IMethodsSlice;

export const methodsSlice = createSlice({
  name: "methods",
  initialState: {
    methods: methodsInitialState.methods,
  },
  reducers: {
    setMethods: (state, action: PayloadAction<IMethodsSlice["methods"]>) => {
      state.methods = action.payload;
    },
    resetMethodsSliceState: (state) => {
      state.methods = methodsInitialState.methods;
    },
  },
});

export const { resetMethodsSliceState, setMethods } = methodsSlice.actions;

type SelectMethodById = {
  [key: number]: ArrayElement<IMethodsSlice["methods"]>;
};

const selectSelf = (state: RootState) => state.methods;

export const selectMethods = createSelector(
  selectSelf,
  (blocks) => blocks.methods
);

export const selectMethodById = createSelector(selectSelf, (methods) =>
  methods.methods?.reduce<SelectMethodById>(extractObjectById, {})
);

export default methodsSlice.reducer;
