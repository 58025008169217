import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { handleStorage } from "./services/handleStorage";
import { setApiError } from "./store/commonSlice";
import store from "./store/store";
import updateAuthToken from "./utils/updateAuthToken";

const config: AxiosRequestConfig = {
  baseURL:
      process.env.REACT_APP_ENVIRONMENT === 'prod'
          ? 'https://apis.numdes.com/sys_eng/schema_design_backend/main'
          : 'https://apis.numdes.com/sys_eng/schema_design_backend/main',
  headers: {
    "Content-Type": "application/json",
  },
};

export const api = axios.create(config);

const handleRequest = (request: AxiosRequestConfig) => {
  if (!request.headers?.Authorization) {
    const token = handleStorage.getToken();
    if (!token) {
      return request;
    }
    updateAuthToken(token);
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token}`,
      "access-token": `${token}`,
    };
  }
  return request;
};

const handleResponse = async (res: AxiosResponse) => {
  return res;
};

const handleError = async (error: any) => {
  if (error.response?.status) {
    switch (error.response?.status) {
      case 401: {
        handleStorage.removeToken();
        updateAuthToken(false);
        if (!window.location.href.includes("login")) {
          window.location.replace("/login");
        }
        if (error.response?.data?.detail === "Incorrect username or password") {
          store.dispatch(
            setApiError({
              isError: true,
              status: error.response?.status,
              timeStamp: new Date().toISOString(),
              message: error.response?.data?.detail,
            })
          );
        }
        break;
      }
      case 400: {
        store.dispatch(
          setApiError({
            isError: true,
            status: error.response?.status,
            timeStamp: new Date().toISOString(),
            message: error.response?.data?.detail,
          })
        );
        break;
      }
      default: {
        store.dispatch(
          setApiError({
            isError: true,
            status: error.response?.status,
            timeStamp: new Date().toISOString(),
            message: error.response?.data?.detail,
          })
        );
      }
    }
  } else {
    store.dispatch(
      setApiError({
        isError: true,
        message: error.message,
      }),
    );
  }
  return Promise.reject(error);
};

api.interceptors.request.use(handleRequest);

api.interceptors.response.use((res: AxiosResponse) => handleResponse(res), handleError);
