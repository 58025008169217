import React, {ChangeEvent} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SingleValue } from "react-select";

import classNames from "classnames";

import Dropdown from "../../../../../components/Dropdown";
import { MyOptionType } from "../../../../../components/Dropdown/handleStyles";
import Input from "../../../../../components/Input";
import { components } from "../../../../../generated/apiTypes";
import DeleteIcon from "../../../../../images/icn-delete";
import DownArrowIcon from "../../../../../images/icn-sort-down";
import {
  updateConnectionById,
  updateConnectionsByCpId,
} from "../../../../../services/apiRequests";
import {selectApiError, setApiError, setShowDialog} from "../../../../../store/commonSlice";
import {
  downConnection,
  selectAvailableBlocks,
  selectConnectionPoint,
  selectConnections,
  selectConnectionsBlocks,
  upConnection,
  updateConnection,
  updateConnectionsBlocks,
} from "../../../../../store/connectionsSlice";
import {
  getBlocksOptionsFromAvailableBlocks,
  getCpOptionsFromAvailableBlocks,
} from "../../../../../utils/optionsFromArray";

interface IConnectionsRowProps {
  index: number;
  length: number;
  connection: components["schemas"]["Connection"];
  scrollableRef: React.RefObject<any>;
}

const ConnectionsRow: React.FC<IConnectionsRowProps> = ({
  index,
  length,
  connection,
  scrollableRef,
}) => {
  const dispatch = useDispatch();
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const availableBlocks = useSelector(selectAvailableBlocks);
  const connections = useSelector(selectConnections);
  const connectionsBlocks = useSelector(selectConnectionsBlocks);
  const [blocksOptions, setBlocksOptions] = React.useState<MyOptionType[]>();
  const [allBlocksOptions, setAllBlocksOptions] =
    React.useState<MyOptionType[]>();
  const [cpOptions, setCpOptions] = React.useState<MyOptionType[]>();
  const [reorder, setReorder] = React.useState<boolean>(false);
  const apiError = useSelector(selectApiError);

  React.useEffect(() => {
    if (availableBlocks && selectedConnectionPoint?.type === "OUT") {
      setAllBlocksOptions(getBlocksOptionsFromAvailableBlocks(availableBlocks));
    }
  }, [availableBlocks]);

  React.useEffect(() => {
    if (availableBlocks && selectedConnectionPoint?.type === "OUT") {
      setBlocksOptions(
        getBlocksOptionsFromAvailableBlocks(
          availableBlocks.filter(
            (item) =>
              !connectionsBlocks?.find(
                (connection) => connection.block.id === item.id
              )
          )
        )
      );
    }
  }, [availableBlocks, connections?.length, connection.input_connection_point?.block]);

  React.useEffect(() => {
    if (connections && reorder && selectedConnectionPoint?.id) {
      updateConnectionsByCpId(
        selectedConnectionPoint.id,
        connections?.map((item) => {
          return { id: item.id };
        })
      );
      setReorder(false);
    }
  }, [connections]);

  React.useEffect(() => {
    if (connection.input_connection_point?.block) {
      dispatch(
        updateConnectionsBlocks({
          id: connection.id,
          block: connection.input_connection_point?.block,
        })
      );
      const block = availableBlocks?.find(
        (item) => item.id === connection.input_connection_point?.block.id
      );
      if (block && selectedConnectionPoint?.type === "OUT")
        setCpOptions(getCpOptionsFromAvailableBlocks(block));
    }
  }, [connection.input_connection_point?.block, availableBlocks]);

  const onBlockChange = (option: SingleValue<MyOptionType>) => {
    if (connection?.id && option?.value) {
      dispatch(
        updateConnectionsBlocks({
          id: connection.id,
          block: { id: parseInt(option.value), name: option.label },
        })
      );
      dispatch(
        updateConnection({
          ...connection,
          input_connection_point: undefined,
        })
      );
      const block = availableBlocks?.find(
        (item) => item.id.toString() === option.value
      );
      if (block && selectedConnectionPoint?.type === "OUT")
        setCpOptions(getCpOptionsFromAvailableBlocks(block));
    }
  };

  const onCpChange = (option: SingleValue<MyOptionType>) => {
    if (option?.value && blockValue?.value) {
      dispatch(
        updateConnection({
          ...connection,
          input_connection_point: {
            id: parseInt(option?.value),
            name: option?.label,
            block: { id: parseInt(blockValue.value), name: blockValue.label },
          },
        })
      );
      updateConnectionById(connection.id, {
        input_connection_point_id: parseInt(option?.value),
      });
    }
  };

  const onUpArrowClick = () => {
    dispatch(upConnection(connection.id));
    setReorder(true);
  };

  const onDownArrowClick = () => {
    dispatch(downConnection(connection.id));
    setReorder(true);
  };

  const onConditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (apiError.isError)
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        })
      );
    dispatch(
      updateConnection({
        ...connection,
        condition: e.target.value,
      })
    );
    updateConnectionById(connection.id, {
      condition: e.target.value,
    });
  };

  const onDeleteClick = () => {
    dispatch(
      setShowDialog({
        objectType: "CONNECTION",
        dialogType: "DELETE_CONFIRM",
        objectId: connection.id?.toString(),
      })
    );
  };

  const blockValue = allBlocksOptions?.find(
    (item) =>
      item.value ===
      connectionsBlocks
        ?.find((block) => block.id === connection.id)
        ?.block.id.toString()
  );

  return (
    <div
      className={classNames(
        "connection-row__container",
        selectedConnectionPoint?.type === "IN" && "input-row-container"
      )}
      key={index}
    >
      {selectedConnectionPoint?.type === "OUT" && (
        <button
          className="block__control-arrows connection-row__arrows"
          onClick={(e) => e.stopPropagation()}
        >
          <button
            className="block__control-arrow up-arrow"
            disabled={index === 0}
            onClick={onUpArrowClick}
          >
            <DownArrowIcon />
          </button>
          <button
            className="block__control-arrow"
            disabled={index === length - 1}
            onClick={onDownArrowClick}
          >
            <DownArrowIcon />
          </button>
        </button>
      )}
      <div
        className={classNames(
          "connection-row__priority-order",
          selectedConnectionPoint?.type === "IN" && "input-point-order"
        )}
      >
        {selectedConnectionPoint?.type === "OUT" ? index + 1 : ""}
      </div>
      <div className="connection-row__select-container">
        <Dropdown
          placeholder=""
          isSearchable={false}
          isDisabled={selectedConnectionPoint?.type === "IN"}
          options={blocksOptions}
          scrollableRef={scrollableRef}
          value={
            selectedConnectionPoint?.type === "OUT"
              ? blockValue || null
              : ({
                  value: connection?.out_connection_point?.block.id?.toString(),
                  label: connection?.out_connection_point?.block.name,
                } as SingleValue<MyOptionType>)
          }
          isOptionDisabled={(option) => option?.disabled as boolean}
          onChange={onBlockChange}
        />
      </div>
      <div className="connection-row__select-container">
        <Dropdown
          placeholder=""
          isSearchable={false}
          options={cpOptions}
          scrollableRef={scrollableRef}
          value={
            selectedConnectionPoint?.type === "OUT"
              ? cpOptions?.find(
                  (item) =>
                    item.value ===
                    connection.input_connection_point?.id.toString()
                ) || null
              : ({
                  value: connection?.out_connection_point?.id?.toString(),
                  label: connection?.out_connection_point?.name,
                } as SingleValue<MyOptionType>)
          }
          onChange={onCpChange}
          isDisabled={
            selectedConnectionPoint?.type === "IN" || !blockValue?.value
          }
        />
      </div>
      <div className="connection-row__select-container">
        <Input
          placeholder=""
          value={connection?.condition}
          disabled={selectedConnectionPoint?.type === "IN"}
          onChange={onConditionChange}
          error={
            apiError.status === 422 &&
            (
              apiError.message as { loc: any; msg: string; type: string }[]
            )[0].loc.includes("condition")
          }
        />
      </div>
      <button
        className="facility__delete-btn"
        disabled={selectedConnectionPoint?.type === "IN"}
        onClick={onDeleteClick}
      >
        {selectedConnectionPoint?.type === "OUT" && <DeleteIcon />}
      </button>
    </div>
  );
};

export default ConnectionsRow;
