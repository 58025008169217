import React from "react";
import { useSelector } from "react-redux";

import { selectSelectedMatrixBlock } from "../../../../store/matrixSlice";
import InputConnectionPoint from "./InputConnectionPoint";
import OutputConnectionPoint from "./OutputConnectionPoint";

import "./style.scss";

const ConnectionPoints: React.FC = () => {
  const selectedBlock = useSelector(selectSelectedMatrixBlock);

  return (
    <div>
      <div className="facility-block-details__parameters-title-row">
        Точки подключения
      </div>
      <div className="facility-block-details__connection-points-panel">
        {selectedBlock.instance?.input_connection_points.map((item) => (
          <InputConnectionPoint connectionPoint={item} />
        ))}
        {selectedBlock.instance?.out_connection_points.map((item) => (
          <OutputConnectionPoint connectionPoint={item} />
        ))}
      </div>
    </div>
  );
};

export default ConnectionPoints;
