import * as React from "react";

const Logo = (props: any) => {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.688 6.563h3.75a1.856 1.856 0 001.874-1.875 1.856 1.856 0 00-1.874-1.875h-3.75a1.856 1.856 0 00-1.875 1.874c0 1.051.823 1.875 1.874 1.875zm18.875 0h3.75a1.856 1.856 0 001.875-1.875 1.856 1.856 0 00-1.875-1.875h-3.75a1.856 1.856 0 00-1.875 1.874c0 1.051.824 1.875 1.875 1.875zm-5.688 18.875h-3.75a1.856 1.856 0 00-1.875 1.875c0 1.05.824 1.875 1.875 1.875h3.75a1.856 1.856 0 001.875-1.875 1.856 1.856 0 00-1.875-1.875zm13.188-10.375H.938a.937.937 0 100 1.874h30.125a.937.937 0 100-1.875z"
        fill="url(#prefix__paint0_linear_1088_4171)"
      />
      <path
        d="M6.625 13.188A2.816 2.816 0 003.812 16a2.816 2.816 0 002.813 2.813A2.816 2.816 0 009.438 16a2.816 2.816 0 00-2.813-2.813zM16 18.811A2.816 2.816 0 0018.813 16 2.816 2.816 0 0016 13.187 2.816 2.816 0 0013.187 16 2.816 2.816 0 0016 18.813zm9.375-5.625A2.816 2.816 0 0022.562 16a2.816 2.816 0 002.813 2.813A2.816 2.816 0 0028.188 16a2.816 2.816 0 00-2.813-2.813zM5.906 10.97a.906.906 0 001.313 0L8.83 9.375h3.357a.928.928 0 00.937-.938v-7.5A.928.928 0 0012.187 0H.938A.928.928 0 000 .938v7.5c0 .524.412.937.938.937h3.356l1.612 1.594zM3.75 4.688c0-.526.412-.938.938-.938h3.75c.525 0 .937.412.937.938a.928.928 0 01-.938.937h-3.75a.928.928 0 01-.937-.938zm17.875 17.937h-3.356l-1.613-1.594a.906.906 0 00-1.312 0l-1.613 1.594h-3.356a.928.928 0 00-.938.938v7.5c0 .524.413.937.938.937h11.25a.928.928 0 00.938-.938v-7.5a.928.928 0 00-.938-.937zm-3.75 5.625h-3.75a.928.928 0 01-.938-.938c0-.525.413-.937.938-.937h3.75c.525 0 .938.412.938.938a.928.928 0 01-.938.937zM31.063 0h-11.25a.928.928 0 00-.938.938v7.5c0 .524.412.937.938.937h3.356l1.612 1.594a.906.906 0 001.313 0l1.612-1.594h3.357A.928.928 0 0032 8.437v-7.5A.928.928 0 0031.062 0zm-3.75 5.625h-3.75a.928.928 0 01-.938-.938c0-.525.412-.937.938-.937h3.75c.525 0 .937.412.937.938a.928.928 0 01-.938.937z"
        fill="url(#prefix__paint1_linear_1088_4171)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_1088_4171"
          x1={16}
          y1={29.188}
          x2={16}
          y2={2.813}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C3FFE8" />
          <stop offset={0.997} stopColor="#F0FFF4" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear_1088_4171"
          x1={16}
          y1={32}
          x2={16}
          y2={0}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B59C" />
          <stop offset={1} stopColor="#9CFFAC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Logo;
