import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import DeleteIcon from "../../../../images/icn-delete";
import {
  selectSelectedBlock,
  setSelectedBlockId,
  setSelectedBlockInstance,
} from "../../../../store/blocksSlice";
import { setShowDialog } from "../../../../store/commonSlice";

import "./style.scss";

interface IBlock {
  id: number;
  name?: string;
}

const Block: React.FC<IBlock> = ({ id, name }) => {
  const dispatch = useDispatch();
  const selectedBlock = useSelector(selectSelectedBlock);

  const onDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      setShowDialog({
        objectName: name,
        objectType: "BLOCK",
        dialogType: "DELETE_CONFIRM",
        objectId: id.toString(),
      })
    );
  };

  const onBlockClick = () => {
    dispatch(setSelectedBlockId(id));
    if (selectedBlock?.id !== id) dispatch(setSelectedBlockInstance(undefined));
  };

  return (
    <button
      key={id}
      className={classNames(
        "block__container",
        selectedBlock?.id === id && "selected"
      )}
      onClick={onBlockClick}
    >
      <span className="block__title">{name}</span>
      <button className="block__delete-btn" onClick={onDeleteClick}>
        <DeleteIcon />
      </button>
    </button>
  );
};

export default Block;
