import * as React from "react";
import { components, DropdownIndicatorProps } from "react-select";

import DropdownArrow from "../../images/components/dropdown/indicator";
import { MyOptionType } from "./handleStyles";

const DropdownIndicator = (
  props: DropdownIndicatorProps<MyOptionType, false>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
