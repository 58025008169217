import React from "react";
import { useDispatch } from "react-redux";

import { getTableHeads } from "../../../services/apiRequests";
import { setTableHeads } from "../../../store/equipmentSlice";
import { setSelectedMatrixBlock } from "../../../store/matrixSlice";

const useTableHeads = () => {
  const dispatch = useDispatch();

  const fetchTableHeads = async () => {
    try {
      const response = await getTableHeads();
      if (response?.data) {
        dispatch(setTableHeads(response.data));
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    dispatch(setSelectedMatrixBlock({}));
    fetchTableHeads();
  }, []);

  return;
};

export default useTableHeads;
