import React from "react";
import { useSelector } from "react-redux";
import { useLocation,useNavigate } from "react-router-dom";

import { selectApiError } from "../../store/commonSlice";

import "./style.scss";

const ApiErrorUI: React.FC = () => {
  const apiError = useSelector(selectApiError);
  const navigate = useNavigate();
  const location = useLocation();

  const redirect = () => {
    navigate("/");
  };

  return (
    <div className="error-page__container">
      <div className="error-page__content">
        <div className="title error-page__title">Произошла ошибка</div>
        <div className="error-page__codes">
          <div className="error-page__code normal-text">
            {apiError?.status && `STATUS_CODE_${apiError?.status}`}
          </div>
          <div className="error-page__code normal-text">
            {apiError?.key && `KEY_${apiError?.key}`}
          </div>
          <div className=" error-page__code normal-text">
            {apiError?.timeStamp && `TIME_STAMP_${apiError?.timeStamp}`}
          </div>
        </div>
        <div className="error-page__info normal-text">
          {apiError?.message && `DETAILS_${apiError?.message}`}
        </div>
        {!location.pathname.includes("/login") && (
          <button
            className="error-page__link button-text underlined"
            onClick={redirect}
          >
            На главную
          </button>
        )}
      </div>
    </div>
  );
};

export default ApiErrorUI;
