import React from "react";
import { useDispatch } from "react-redux";

import { getBlocks } from "../../../services/apiRequests";
import {setBlocks, setSelectedBlock} from "../../../store/blocksSlice";

const useBlocks = () => {
  const dispatch = useDispatch();

  const fetchBlocks = async () => {
    try {
      const response = await getBlocks();
      if (response?.data) dispatch(setBlocks(response.data));
      if (response.data.length > 0)
        dispatch(setSelectedBlock(response.data[0]));
    } catch (e) {
      //
    }
  };
  React.useEffect(() => {
    fetchBlocks();
  }, []);

  return { fetchBlocks };
};

export default useBlocks;
