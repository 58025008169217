import React from "react";
import { Route, Routes } from "react-router-dom";

import WebFont from "webfontloader";

import Blocks from "./pages/Blocks/blocks";
import Equipment from "./pages/Equipment/equipment";
import Facility from "./pages/Facility/facility";
import Home from "./pages/HomePage/homePage";
import Login from "./pages/Login/login";
import Methods from "./pages/Methods/methods";
import NotFound from "./pages/NotFoundPage/notFoundPage";

import "./App.css";

const App: React.FC = () => {
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["Source Sans Pro"],
      },
    });
  }, []);
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/blocks" element={<Blocks />} />
      <Route path="/equipment" element={<Equipment />} />
      <Route path="/methods" element={<Methods />} />
      <Route path="/facility=:facility_id" element={<Facility />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
