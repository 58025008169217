import { components } from "../generated/apiTypes";

export const sortParamsAscByColumnKey = (property: string, numerical?: boolean) => {
  return function (
    a: components["schemas"]["EquipmentItemParams"],
    b: components["schemas"]["EquipmentItemParams"]
  ) {
    const ixA = a["params"].findIndex((item) => item.column_key === property);
    const ixB = b["params"].findIndex((item) => item.column_key === property);
    if (numerical) {
      return (
        parseFloat(a["params"][ixA]?.value) -
        parseFloat(b["params"][ixB]?.value)
      );
    }
    return a["params"][ixA]?.value.toLowerCase() <
      b["params"][ixB]?.value.toLowerCase()
      ? -1
      : a["params"][ixA]?.value.toLowerCase() >
        b["params"][ixB]?.value.toLowerCase()
      ? 1
      : 0;
  };
};

export const sortParamsDescByColumnKey = (property: string, numerical?: boolean) => {
  return function (
    a: components["schemas"]["EquipmentItemParams"],
    b: components["schemas"]["EquipmentItemParams"]
  ) {
    const ixA = a["params"].findIndex((item) => item.column_key === property);
    const ixB = b["params"].findIndex((item) => item.column_key === property);
    if (numerical) {
      return (
        parseFloat(b["params"][ixB]?.value) -
        parseFloat(a["params"][ixA]?.value)
      );
    }
    return a["params"][ixA]?.value.toLowerCase() >
      b["params"][ixB]?.value.toLowerCase()
      ? -1
      : a["params"][ixA]?.value.toLowerCase() <
        b["params"][ixB]?.value.toLowerCase()
      ? 1
      : 0;
  };
};
