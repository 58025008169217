import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8642 7.69862C11.0453 7.85087 11.0453 8.14913 10.8642 8.30138L6.56056 11.9203C6.33161 12.1129 6 11.9346 6 11.619L6 4.38103C6 4.06542 6.33161 3.88713 6.56056 4.07966L10.8642 7.69862Z"
        fill="#CCCDCD"
      />
    </svg>
  );
};

export default Icon;
