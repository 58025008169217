import React from "react";
import {useDispatch, useSelector} from "react-redux";

import { getMethods } from "../../../services/apiRequests";
import {
  selectSelectedMethod,
  setSelectedMethodId,
} from "../../../store/methodSlice";
import { setMethods } from "../../../store/methodsSlice";

const useMethods = () => {
  const dispatch = useDispatch();
  const method = useSelector(selectSelectedMethod);

  const fetchMethods = async () => {
    try {
      const response = await getMethods();
      if (response?.data) dispatch(setMethods(response.data));
      if (response.data.length > 0 && !method.id)
        dispatch(setSelectedMethodId(response.data[0].id));
    } catch (e) {
      //
    }
  };
  React.useEffect(() => {
    fetchMethods();
  }, []);

  return { fetchMethods };
};

export default useMethods;
