import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { formatInTimeZone } from 'date-fns-tz';

import ApiErrorUI from "../../components/ApiErrorUI/apiError";
import Button from "../../components/Button";
import DialogWindow from "../../components/DialogWindow";
import SearchInput from "../../components/SearchInput";
import { components } from "../../generated/apiTypes";
import { selectApiError, setShowDialog } from "../../store/commonSlice";
import { selectFacilities } from "../../store/facilitiesSlice";
import Facility from "./Facility/facility";
import Header from "./Header/header";
import useCleanup from "./hooks/use-cleanup";
import useFacilities from "./hooks/use-facilities";

import "./style.scss";

const HomePage: React.FC = () => {
  useFacilities();
  useCleanup();
  const dispatch = useDispatch();
  const apiError = useSelector(selectApiError);
  const facilities = useSelector(selectFacilities);
  const [searchInputValue, setSearchInputValue] = React.useState<string>("");
  const [filteredFacilities, setFilteredFacilities] = React.useState<
    components["schemas"]["MainFacilityShort"][]
  >([]);

  React.useEffect(() => {
    if (facilities) setFilteredFacilities(facilities);
  }, [facilities]);

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    if (facilities)
      setFilteredFacilities(
        facilities.filter((item) =>
          item.name.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
  };

  const onCreateFacility = async () => {
    dispatch(setShowDialog({ dialogType: "CHOICE", objectType: "FACILITY" }));
  };

  return (
    <div className="home-page__container">
      <Header />
      <DialogWindow />
      {apiError?.isError ? (
        <ApiErrorUI />
      ) : (
        <div className="home-page__content">
          <div className="home-page__title">Объекты обустройства</div>
          <div className="home-page__controls-row">
            <div className="home-page__search-input-wrapper">
              <SearchInput
                onInput={onChangeSearchInputValue}
                value={searchInputValue}
                placeholder="Объект обустройства"
                containerClassName="home-page__search-input-container"
              />
            </div>
            <Button onClick={onCreateFacility}>
              Создать объект обустройства
            </Button>
          </div>
          {filteredFacilities &&
            filteredFacilities?.length > 0 &&
            filteredFacilities?.map((item) => (
              <Facility
                key={item?.id?.toString() as string}
                id={item?.id?.toString() as string}
                name={item?.name}
                dateTime={formatInTimeZone(
                  new Date(`${item.created_at}Z`),
                  Intl.DateTimeFormat().resolvedOptions().timeZone,
                  "dd MMM yyyy HH:mm",
                  {
                    locale: ru,
                  }
                )}
              />
            ))}
          {filteredFacilities &&
            filteredFacilities?.length === 0 &&
            !searchInputValue && (
              <div className="home-page__empty-message">
                Не создано ни одного объекта обустройства
              </div>
            )}
          {filteredFacilities &&
            filteredFacilities?.length === 0 &&
            searchInputValue && (
              <div className="home-page__empty-message">
                Не найдены объекты обустройства соответствующие данному запросу
              </div>
            )}
        </div>
      )}
    </div>
  );
};

export default HomePage;
