import React from "react";
import { useDispatch } from "react-redux";

import { getFacilities } from "../../../services/apiRequests";
import { setFacilities } from "../../../store/facilitiesSlice";

const useFacilities = () => {
  const dispatch = useDispatch();

  const fetchFacilities = async () => {
    try {
      const response = await getFacilities();
      if (response?.data) dispatch(setFacilities(response.data));
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchFacilities();
  }, []);

  return;
};

export default useFacilities;
