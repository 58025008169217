import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMethodById } from "../../../services/apiRequests";
import {
  selectSelectedMethod,
  setSelectedMethodInstance,
} from "../../../store/methodSlice";

const useSelectedMethod = () => {
  const dispatch = useDispatch();
  const selectedMethod = useSelector(selectSelectedMethod);

  const fetchMethodById = async () => {
    if (selectedMethod.id !== undefined) {
      dispatch(setSelectedMethodInstance(undefined));
      try {
        const response = await getMethodById(selectedMethod.id.toString());
        if (response.data) dispatch(setSelectedMethodInstance(response.data));
      } catch (e) {
        //
      }
    }
  };

  React.useEffect(() => {
    fetchMethodById();
  }, [selectedMethod.id]);

  return;
};

export default useSelectedMethod;
