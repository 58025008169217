import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.944 10.003l2.866-2.86a.67.67 0 10-.946-.947l-2.86 2.867-2.86-2.867a.67.67 0 10-.947.947l2.867 2.86-2.867 2.86a.666.666 0 000 .947.667.667 0 00.947 0l2.86-2.867 2.86 2.867a.667.667 0 001.092-.217.667.667 0 00-.146-.73l-2.866-2.86z"
        fill="#999"
        opacity={0.6}
      />
    </svg>
  );
}

export default Icon;
