import { MyOptionType } from "../components/Dropdown/handleStyles";
import { components } from "../generated/apiTypes";

export const getOptionsFromArray = (arr: string[]) => {
  const options: MyOptionType[] = [];
  arr?.map((item) => options.push({ label: item, value: item }));
  return options;
};

export const getOptionsFromClassArr = (
  objectArr: components["schemas"]["EquipmentClass"][]
) => {
  const options: MyOptionType[] = [];
  objectArr?.map((item) =>
    options.push({ label: item.name, value: item.id.toString() })
  );
  return options;
};

export const getBlocksOptionsFromAvailableBlocks = (
  objectArr: components["schemas"]["AvailableBlock"][]
) => {
  const options: MyOptionType[] = [];
  objectArr
    ?.filter((block) => block.input_connection_points.length > 0)
    .map((item) =>
      options.push({ label: item.name, value: item.id.toString() })
    );
  return options;
};

export const getCpOptionsFromAvailableBlocks = (
  block: components["schemas"]["AvailableBlock"]
) => {
  const options: MyOptionType[] = [];
  block?.input_connection_points?.map((item) =>
    options.push({ label: item.name, value: item.id.toString() })
  );
  return options;
};
