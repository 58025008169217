import React from "react";
import { useDispatch } from "react-redux";

import { getOptions } from "../../../services/apiRequests";
import {
  setAbbreviationsOptions,
  setBlockTypesOptions,
  setEquipmentClassesOptions,
  setGidratOptions, setHints,
  setMolarFlowOptions,
  setParafinOptions,
  setPressureOptions,
  setTemperatureOptions,
  setTypesOptions,
} from "../../../store/optionsSlice";
import {
  getOptionsFromArray,
  getOptionsFromClassArr,
} from "../../../utils/optionsFromArray";

const useOptions = () => {
  const dispatch = useDispatch();

  const fetchOptions = async () => {
    try {
      const response = await getOptions();
      if (response?.data) {
        dispatch(
          setGidratOptions(getOptionsFromArray(response.data?.in_cp?.gidrat))
        );
        dispatch(
          setParafinOptions(getOptionsFromArray(response.data?.in_cp?.parafin))
        );
        dispatch(
          setTypesOptions(getOptionsFromArray(response.data?.in_cp?.types))
        );
        dispatch(
          setTemperatureOptions(
            getOptionsFromArray(response.data?.in_cp?.temperature)
          )
        );
        dispatch(
          setPressureOptions(
            getOptionsFromArray(response.data?.in_cp?.pressure)
          )
        );
        dispatch(
          setMolarFlowOptions(
            getOptionsFromArray(response.data?.in_cp?.molar_flow)
          )
        );
        dispatch(
          setBlockTypesOptions(getOptionsFromArray(response.data?.block_types))
        );
        dispatch(
          setAbbreviationsOptions(
            getOptionsFromArray(response.data?.block_abbreviations)
          )
        );
        dispatch(
          setEquipmentClassesOptions(
            getOptionsFromClassArr(response.data?.equipment_classes)
          )
        );
        dispatch(setHints(response.data?.hints));
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    fetchOptions();
  }, []);

  return { fetchOptions };
};

export default useOptions;
