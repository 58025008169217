import * as React from "react";

import classNames from "classnames";

import "./style.scss";

interface ITextArea extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
}

const TextArea: React.FC<ITextArea> = ({ label, ...props }) => {
  return (
    <div className="textarea__container">
      {label && <span className="input-label">{label}</span>}
      <textarea className={classNames("textarea")} {...props} />
    </div>
  );
};

export default TextArea;
