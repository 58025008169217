import * as React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import ApiErrorUI from "../../components/ApiErrorUI/apiError";
import Layout from "../../components/DatabasePageLayout/layout";
import DialogWindow from "../../components/DialogWindow";
import { selectApiError } from "../../store/commonSlice";
import { selectSelectedMethod } from "../../store/methodSlice";
import ArticlesTree from "./components/ArticlesTree/articlesTree";

import "./styles.scss";

const Methods: React.FC = () => {
  const apiError = useSelector(selectApiError);
  const article = useSelector(selectSelectedMethod);

  return (
    <>
      <Layout pageTitle="База методик">
        <DialogWindow />
        {apiError.isError ? (
          <ApiErrorUI />
        ) : (
          <div className={classNames("blocks__container")}>
            <ArticlesTree />
            <div className="blocks__scrollable-panel">
              <div className="article__container">
                {article.instance?.description}
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Methods;
