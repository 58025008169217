import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import IconClose from "../../../../../images/icn-close";
import {
  resetConnectionsSliceState,
  selectConnectionPoint,
  selectConnections,
} from "../../../../../store/connectionsSlice";
import useAvailablePoint from "../../../hooks/use-avavilable-points-to-connect";
import useConnections from "../../../hooks/use-connections";
import ConnectionsRow from "./ConnectionRow";
import ControlRow from "./ControlRow";
import LabelsRow from "./LabelsRow";

import "./style.scss";

const ConnectionsPanel: React.FC = () => {
  useConnections();
  useAvailablePoint();
  const dispatch = useDispatch();
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const connections = useSelector(selectConnections);
  const scrollableRef = React.useRef(null);

  const closePanel = () => {
    dispatch(resetConnectionsSliceState());
  };

  return (
    <div className="connections-panel__container">
      <div className="connections-panel__header">
        <div>
          {selectedConnectionPoint?.name && (
            <span className="connections-panel__cp-name">
              {selectedConnectionPoint.name}
            </span>
          )}
          ВАРИАНТЫ ПОДКЛЮЧЕНИЙ
        </div>
        <button className="connections-panel__close-btn" onClick={closePanel}>
          <IconClose />
        </button>
      </div>
      {connections?.length !== undefined && connections?.length > 0 && (
        <LabelsRow />
      )}
      {connections?.length !== undefined && connections?.length > 0 ? (
        <div className="connections-panel__scrollable" ref={scrollableRef}>
          {connections?.map((item, index, arr) => (
            <ConnectionsRow
              scrollableRef={scrollableRef}
              index={index}
              length={arr.length}
              connection={item}
            />
          ))}
        </div>
      ) : (
        <div
          className={classNames(
            "connections-panel__empty-message",
            selectedConnectionPoint?.type === "IN" && "input-empty-message"
          )}
        >
          {selectedConnectionPoint?.type === "IN"
            ? "Не задано ни одной опции подключения (Опции задаются на OUT точках)"
            : "Не добавлено ни одной опции подключения"}
        </div>
      )}
      {((connections?.length && connections?.length > 0) ||
        selectedConnectionPoint?.type === "OUT") && <ControlRow />}
    </div>
  );
};

export default ConnectionsPanel;
