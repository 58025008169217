import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { getFacilityById } from "../../../services/apiRequests";
import {setFacility, setVersion} from "../../../store/facilitySlice";

type FacilityParams = {
  facility_id: string;
};

const useFacility = () => {
  const dispatch = useDispatch();
  const { facility_id } = useParams<FacilityParams>();

  const fetchFacility = async () => {
    if (facility_id !== undefined) {
      try {
        const response = await getFacilityById(facility_id);
        if (response?.data) {
          dispatch(setFacility(response.data));
          dispatch(
            setVersion(
              response.data.versions.find(
                (item) => item.id === response.data.active_version_id
              )
            )
          );
        }
      } catch (e) {
        //
      }
    }
  };

  React.useEffect(() => {
    fetchFacility();
  }, [facility_id]);

  return;
};

export default useFacility;
