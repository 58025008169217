import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectBlockById,
  selectSelectedBlock,
  setSelectedBlockInstance,
} from "../../../store/blocksSlice";

const useSelectedBlock = () => {
  const dispatch = useDispatch();
  const selectedBlock = useSelector(selectSelectedBlock);
  const blockById = useSelector(selectBlockById);

  const extractBlockById = () => {
    if (selectedBlock.id !== undefined && blockById) {
      dispatch(setSelectedBlockInstance(undefined));
      dispatch(setSelectedBlockInstance(blockById[selectedBlock.id]));
    }
  };

  React.useEffect(() => {
    extractBlockById();
  }, [
    selectedBlock.id,
    blockById && selectedBlock.id && blockById[selectedBlock.id],
  ]);

  return;
};

export default useSelectedBlock;
