import * as React from "react";
import { useSelector } from "react-redux";

import ApiErrorUI from "../../components/ApiErrorUI/apiError";
import Layout from "../../components/DatabasePageLayout/layout";
import DialogWindow from "../../components/DialogWindow";
import { selectApiError } from "../../store/commonSlice";
import { selectFacility } from "../../store/facilitySlice";
import BlockDetails from "./components/BlockDetails/blockDetails";
import BlockImagePanel from "./components/BlockImagePanel/blockImagePanel";
import BlocksTree from "./components/BlocksTree/blocksTree";
import useCleanup from "./hooks/use-cleanup";
import useFacility from "./hooks/use-facility";

import "./style.scss";

const Facility: React.FC = () => {
  useCleanup();
  useFacility();
  const facility = useSelector(selectFacility);
  const apiError = useSelector(selectApiError);

  const errorToShow =
    apiError?.isError &&
    !(apiError.message as string)?.includes(
      "Expected at least one connection point"
    ) &&
    !(
      apiError.status === 422 &&
      (apiError.message as { loc: any; msg: string; type: string }[])?.filter(
        (item) => item.type === "value_error"
      )
    );

  return (
    <>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <>
          <DialogWindow />
          <Layout pageTitle={facility?.name}>
            <div className="blocks__container">
              <BlocksTree />
              <BlockImagePanel />
              <BlockDetails />
            </div>
          </Layout>
        </>
      )}
    </>
  );
};

export default Facility;
