import * as React from "react";

const Icon = (props: { classname?: string }) => {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classname}
      {...props}
    >
      <path
        d="M19 12H5M12 19l-7-7 7-7"
        stroke="#999"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
