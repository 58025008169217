export const extractObjectById = <
  T extends {
    id?: number;
  },
>(
  acc: { [key: number]: T },
  cur: T
) => {
  if (cur.id) {
    acc[cur.id] = cur;
  }
  return acc;
};

export const extractObjectByCategory = <
  T extends {
    category?: string;
  }
>(
  acc: { [category: string]: T },
  cur: T
) => {
  if (cur.category) {
    acc[cur.category] = cur;
  }
  return acc;
};
