import React, { ChangeEvent } from "react";
import {useDispatch, useSelector} from "react-redux";
import { SingleValue } from "react-select";

import classNames from "classnames";
import update from "immutability-helper";

import Dropdown from "../../../../components/Dropdown";
import { MyOptionType } from "../../../../components/Dropdown/handleStyles";
import Input from "../../../../components/Input";
import { components } from "../../../../generated/apiTypes";
import IconArrow from "../../../../images/components/dropdown/indicator";
import IconToggle from "../../../../images/icn-toggle";
import {
  updateMatrixBlockOutConnectionPointById
} from "../../../../services/apiRequests";
import {selectSelectedBlock} from "../../../../store/blocksSlice";
import {
  resetConnectionsSliceState,
  selectConnectionPoint,
  selectPanelIsShown,
  setConnectionPoint, setConnections,
  setPanelIsShown,
} from "../../../../store/connectionsSlice";
import {
  selectSelectedMatrixBlock,
  setSelectedMatrixBlockInstance,
  updateMatrixBlock
} from "../../../../store/matrixSlice";
import {selectHints, selectTypesOptions} from "../../../../store/optionsSlice";
import useOptions from "../../../Blocks/hooks/use-options";

import "./style.scss";

interface IInputPointProps {
  connectionPoint: components["schemas"]["OutConnectionPoint"];
}

const OutputConnectionPoint: React.FC<IInputPointProps> = ({
  connectionPoint,
}) => {
  useOptions();
  const dispatch = useDispatch();
  const typesOptions = useSelector(selectTypesOptions);
  const connectionsPanelIsShown = useSelector(selectPanelIsShown);
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const selectedMatrixBlock = useSelector(selectSelectedMatrixBlock);
  const hints = useSelector(selectHints);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const detailsRef = React.useRef<HTMLDivElement>(null);
  const [valueError, setValueError] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (expanded && detailsRef?.current) {
      detailsRef.current.scrollIntoView();
    }
  }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const toggleConnectionsPanel = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (
      connectionsPanelIsShown !== undefined &&
      selectedConnectionPoint?.name === connectionPoint.name &&
      connectionsPanelIsShown
    ) {
      dispatch(resetConnectionsSliceState());
    } else if (
      connectionsPanelIsShown !== undefined &&
      !connectionsPanelIsShown
    ) {
      dispatch(setPanelIsShown(true));
      dispatch(
        setConnectionPoint({
          id: connectionPoint.id,
          name: connectionPoint.name,
          type: "OUT",
        })
      );
    } else if (
      connectionsPanelIsShown !== undefined &&
      connectionsPanelIsShown &&
      selectedConnectionPoint?.name !== connectionPoint.name
    ) {
      dispatch(setConnections());
      dispatch(
        setConnectionPoint({
          id: connectionPoint.id,
          name: connectionPoint.name,
          type: "OUT",
        })
      );
    }
  };

  const onOptionChange = async (
    option: SingleValue<MyOptionType>,
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (
      option?.value &&
      selectedMatrixBlock.instance &&
      selectedMatrixBlock.id
    ) {
      try {
        const index =
          selectedMatrixBlock.instance?.out_connection_points.findIndex(
            ({ id }) => connectionPoint.id === id
          );
        if (index !== undefined && index > -1) {
          const pointsToUpdate = update(
            selectedMatrixBlock.instance?.out_connection_points,
            {
              [index]: {
                $set: {
                  ...selectedMatrixBlock.instance?.out_connection_points[index],
                  [param]: option?.value,
                },
              },
            }
          );
          const objToUpdate = {
            ...selectedMatrixBlock.instance,
            out_connection_points: pointsToUpdate,
          };
          dispatch(setSelectedMatrixBlockInstance(objToUpdate));
          await updateMatrixBlockOutConnectionPointById(
            connectionPoint.id.toString(),
            { [param]: option?.value }
          );
          dispatch(updateMatrixBlock(objToUpdate));
        }
      } catch (e) {
        //
      }
    }
  };

  const onChangeValue = (
    e: ChangeEvent<HTMLInputElement>,
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (valueError.includes(param)) {
      const ixToDelete = valueError.findIndex((item) => item === param);
      if (ixToDelete !== undefined && ixToDelete > -1) {
        const errors = update(valueError, {
          $splice: [[ixToDelete, 1]],
        });
        setValueError(errors);
      }
    }
    if (selectedMatrixBlock.instance && selectedMatrixBlock.id) {
      try {
        const index =
          selectedMatrixBlock.instance?.out_connection_points.findIndex(
            ({ id }) => connectionPoint.id === id
          );
        if (index !== undefined && index > -1) {
          const pointsToUpdate = update(
            selectedMatrixBlock.instance?.out_connection_points,
            {
              [index]: {
                $set: {
                  ...selectedMatrixBlock.instance?.out_connection_points[index],
                  [param]: e.target.value,
                },
              },
            }
          );
          const objToUpdate = {
            ...selectedMatrixBlock.instance,
            out_connection_points: pointsToUpdate,
          };
          dispatch(setSelectedMatrixBlockInstance(objToUpdate));
        }
      } catch (e) {
        //
      }
    }
  };

  const saveValue = async (
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (selectedMatrixBlock.id && selectedMatrixBlock.instance) {
      try {
        const index =
          selectedMatrixBlock.instance?.out_connection_points.findIndex(
            ({ id }) => connectionPoint.id === id
          );
        if (index !== undefined && index > -1) {
          await updateMatrixBlockOutConnectionPointById(
            connectionPoint.id.toString(),
            {
              [param]:
                selectedMatrixBlock.instance?.out_connection_points[index][
                  param
                ],
            }
          );
          dispatch(updateMatrixBlock(selectedMatrixBlock.instance));
        }
      } catch (e) {
        setValueError([...valueError, param]);
      }
    }
  };

  return (
    <div className="facility-block-details__connection-point-container">
      <button
        className="facility-block-details__connection-point-top-row"
        onClick={toggleExpanded}
      >
        <div
          className={classNames(
            "facility-block-details__connection-point-toggle-list",
            {
              expanded,
            }
          )}
        >
          <IconToggle />
          <span className="block__title">{connectionPoint.name}</span>
        </div>
        <button
          className={classNames(
            "facility-block-details__connection-point-btn",
            selectedConnectionPoint?.name === connectionPoint.name && "selected"
          )}
          onClick={toggleConnectionsPanel}
        >
          См. подкл.
          <IconArrow />
        </button>
      </button>
      {expanded && (
        <div
          ref={detailsRef}
          className="facility-block-details__connection-point-details"
        >
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Dropdown
                placeholder=""
                label="Тип"
                options={typesOptions}
                value={typesOptions?.find(
                  (item) => item.value === connectionPoint?.type
                )}
                isSearchable={false}
                onChange={(e) => onOptionChange(e, "type")}
                isDisabled={selectedMatrixBlock?.index === 0}
              />
            </div>
            <div className="block-parameters-element">
              <Input
                placeholder="0.0"
                label="Температура, К"
                value={connectionPoint?.temperature}
                onChange={(e) => onChangeValue(e, "temperature")}
                onBlur={() => saveValue("temperature")}
                disabled={selectedMatrixBlock?.index === 0}
                error={valueError.includes("temperature")}
                hints={hints.filter((hint) =>
                  hint.name.includes(
                    selectedMatrixBlock.instance?.out_connection_points.find(
                      (cp) => cp.id === connectionPoint.id
                    )?.temperature as string
                  )
                )}
                cpId={connectionPoint.id}
                param={"temperature"}
                matrix={true}
              />
            </div>
          </div>
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Input
                placeholder="0.0"
                label="Давление, Pa"
                value={connectionPoint?.pressure}
                onChange={(e) => onChangeValue(e, "pressure")}
                onBlur={() => saveValue("pressure")}
                disabled={selectedMatrixBlock?.index === 0}
                error={valueError.includes("pressure")}
                hints={hints.filter((hint) =>
                  hint.name.includes(
                    selectedMatrixBlock.instance?.out_connection_points.find(
                      (cp) => cp.id === connectionPoint.id
                    )?.pressure as string
                  )
                )}
                cpId={connectionPoint.id}
                param={"pressure"}
                matrix={true}
              />
            </div>
            <div className="block-parameters-element">
              <Input
                placeholder="0.0"
                label="Производительность, моль"
                value={connectionPoint?.molar_flow}
                onChange={(e) => onChangeValue(e, "molar_flow")}
                onBlur={() => saveValue("molar_flow")}
                disabled={selectedMatrixBlock?.index === 0}
                error={valueError.includes("molar_flow")}
                hints={hints.filter((hint) =>
                  hint.name.includes(
                    selectedMatrixBlock.instance?.out_connection_points.find(
                      (cp) => cp.id === connectionPoint.id
                    )?.molar_flow as string
                  )
                )}
                cpId={connectionPoint.id}
                param={"molar_flow"}
                matrix={true}
              />
            </div>
          </div>
          <div className="block-parameters-row">
            <div className="block-parameters-element">
              <Input
                placeholder="0.0"
                label="Температура гидратообразования, К"
                value={connectionPoint?.temperature_gidrat}
                onChange={(e) => onChangeValue(e, "temperature_gidrat")}
                onBlur={() => saveValue("temperature_gidrat")}
                disabled={selectedMatrixBlock?.index === 0}
                error={valueError.includes("temperature_gidrat")}
                hints={hints.filter((hint) =>
                  hint.name.includes(
                    selectedMatrixBlock.instance?.out_connection_points.find(
                      (cp) => cp.id === connectionPoint.id
                    )?.temperature_gidrat as string
                  )
                )}
                cpId={connectionPoint.id}
                param={"temperature_gidrat"}
                matrix={true}
              />
            </div>
            <div className="block-parameters-element">
              <Input
                placeholder="0.0"
                label="Температура парафинообразования, К"
                value={connectionPoint?.temperature_parafin}
                onChange={(e) => onChangeValue(e, "temperature_parafin")}
                onBlur={() => saveValue("temperature_parafin")}
                disabled={selectedMatrixBlock?.index === 0}
                error={valueError.includes("temperature_parafin")}
                hints={hints.filter((hint) =>
                  hint.name.includes(
                    selectedMatrixBlock.instance?.out_connection_points.find(
                      (cp) => cp.id === connectionPoint.id
                    )?.temperature_parafin as string
                  )
                )}
                cpId={connectionPoint.id}
                param={"temperature_parafin"}
                matrix={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OutputConnectionPoint;
