import React from "react";
import {useDispatch, useSelector} from "react-redux";

import classNames from "classnames";

import IconToggle from "../../../images/icn-toggle";
import {selectSelectedClass, setSelectedClass} from "../../../store/equipmentSlice";

import "../style.scss";

interface IToggleList {
  category: string;
  data: { id: number; name: string }[];
}

const ToggleList: React.FC<IToggleList> = ({ category, data }) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const selectedClass = useSelector(selectSelectedClass);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onItemClick = (id: number, category: string) => {
    if (id !== selectedClass?.id)
      dispatch(setSelectedClass({ id: id, category }));
  };

  return (
    <div className="toggle-list__container">
      {
        <button
          className={classNames("block__container toggle-list__category", {
            expanded,
          })}
          onClick={toggleExpanded}
        >
          <IconToggle />
          <span className="block__title">{category}</span>
        </button>
      }
      {expanded &&
        data?.map((item) => (
          <button
            key={item.id}
            className={classNames(
              "block__container toggle-list__item",
              item.id === selectedClass?.id && "selected"
            )}
            onClick={() => {
              onItemClick(item.id, category);
            }}
          >
            <span className="block__title">{item.name}</span>
          </button>
        ))}
    </div>
  );
};

export default ToggleList;
