import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { components } from "../generated/apiTypes";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { IFacilitySlice } from "./types";

const facilityInitialState = initialState as IFacilitySlice;

export const facilitySlice = createSlice({
  name: "facility",
  initialState: {
    facility: facilityInitialState.facility,
    version: facilityInitialState.version,
  },
  reducers: {
    setFacility: (state, action: PayloadAction<IFacilitySlice["facility"]>) => {
      state.facility = action.payload;
    },
    setVersion: (state, action: PayloadAction<IFacilitySlice["version"]>) => {
      state.version = action.payload;
    },
    deleteVersion: (
      state,
      action: PayloadAction<components["schemas"]["FacilityVersion"]["id"]>
    ) => {
      const index = state.facility?.versions?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1) {
        state.facility = update(state.facility, {
          versions: { $splice: [[index, 1]] },
        });
      }
    },
    resetFacilitySliceState: (state) => {
      state.facility = facilityInitialState.facility;
      state.version = facilityInitialState.version;
    },
  },
});

export const {
  setFacility,
  setVersion,
  deleteVersion,
  resetFacilitySliceState,
} = facilitySlice.actions;

const selectSelf = (state: RootState) => state.facility;

export const selectFacility = createSelector(
  selectSelf,
  (facility) => facility.facility
);

export const selectVersion = createSelector(
  selectSelf,
  (facility) => facility.version
);

export default facilitySlice.reducer;
