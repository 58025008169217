import React from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchInput from "../../../../components/SearchInput";
import { components } from "../../../../generated/apiTypes";
import {
  selectSelectedBlock,
  setSelectedBlock,
} from "../../../../store/blocksSlice";
import { selectMethods } from "../../../../store/methodsSlice";
import useMethods from "../../hooks/use-methods";
import useSelectedMethod from "../../hooks/use-selected-method";
import Article from "./Article";

import "./style.scss";

const ArticlesTree: React.FC = () => {
  const dispatch = useDispatch();
  useMethods();
  useSelectedMethod();
  const articles = useSelector(selectMethods);
  const selectedArticle = useSelector(selectSelectedBlock);

  const [searchInputValue, setSearchInputValue] = React.useState<string>("");
  const [filteredArticles, setFilteredArticles] = React.useState<
    components["schemas"]["HintShort"][]
  >([]);

  React.useEffect(() => {
    if (articles) setFilteredArticles(articles);
  }, [articles]);

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    if (articles) {
      const filteredList = articles.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      if (!filteredList.find((item) => item.id === selectedArticle.id)) {
        dispatch(setSelectedBlock({}));
      }
      setFilteredArticles(filteredList);
    }
  };

  return (
    <div className="blocks-tree__container">
      <div>
        <div className="blocks-tree__search-wrapper">
          <SearchInput
            onInput={onChangeSearchInputValue}
            value={searchInputValue}
            placeholder="Название статьи"
            containerClassName="blocks-tree__search-input-container"
          />
        </div>
        <div className="blocks-tree__blocks">
          {filteredArticles &&
            filteredArticles?.length > 0 &&
            filteredArticles?.map((item) => (
              <Article id={item.id} name={item.name} />
            ))}
          {filteredArticles &&
            filteredArticles?.length === 0 &&
            !searchInputValue && (
              <div className="blocks-tree__blocks-empty-message">
                Не добавлена ни одна статья
              </div>
            )}
          {filteredArticles &&
            filteredArticles?.length === 0 &&
            searchInputValue && (
              <div className="blocks-tree__blocks-empty-message">
                Не найдены статьи соответствующие данному запросу
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ArticlesTree;
