import React from "react";
import { useSelector } from "react-redux";

import { selectSelectedBlock } from "../../../../store/blocksSlice";
import OutputPoint from "./OutputPoint";

import "./style.scss";

const OutputPoints: React.FC = () => {
  const selectedBlock = useSelector(selectSelectedBlock);

  return (
    <>
      {selectedBlock?.instance?.out_connection_points?.map((item) => (
        <OutputPoint connectionPoint={item} />
      ))}
    </>
  );
};

export default OutputPoints;
