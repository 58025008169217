import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 16.6666C13.6819 16.6666 16.6667 13.6818 16.6667 9.99992C16.6667 6.31802 13.6819 3.33325 10 3.33325C6.31811 3.33325 3.33334 6.31802 3.33334 9.99992C3.33334 13.6818 6.31811 16.6666 10 16.6666Z"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99985 12.6664V9.99976"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99985 7.33325H10.0065"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Icon;
