import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.8333 6H7.83333C7.09695 6 6.5 6.59695 6.5 7.33333V13.3333C6.5 14.0697 7.09695 14.6667 7.83333 14.6667H13.8333C14.5697 14.6667 15.1667 14.0697 15.1667 13.3333V7.33333C15.1667 6.59695 14.5697 6 13.8333 6Z"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83398 9.99992H3.16732C2.8137 9.99992 2.47456 9.85944 2.22451 9.60939C1.97446 9.35935 1.83398 9.02021 1.83398 8.66659V2.66659C1.83398 2.31296 1.97446 1.97382 2.22451 1.72378C2.47456 1.47373 2.8137 1.33325 3.16732 1.33325H9.16732C9.52094 1.33325 9.86008 1.47373 10.1101 1.72378C10.3602 1.97382 10.5007 2.31296 10.5007 2.66659V3.33325"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Icon;
