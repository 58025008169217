import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { components } from "../generated/apiTypes";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { IFacilitiesSlice } from "./types";

const facilitiesInitialState = initialState as IFacilitiesSlice;

export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    facilities: facilitiesInitialState.facilities,
    editFacilityName: facilitiesInitialState.editFacilityName,
  },
  reducers: {
    setFacilities: (
      state,
      action: PayloadAction<IFacilitiesSlice["facilities"]>
    ) => {
      state.facilities = action.payload;
    },
    setEditFacilityName: (
      state,
      action: PayloadAction<IFacilitiesSlice["editFacilityName"]>
    ) => {
      state.editFacilityName = action.payload;
    },
    deleteFacility: (
      state,
      action: PayloadAction<components["schemas"]["MainFacilityShort"]["id"]>
    ) => {
      const index = state.facilities?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1) {
        state.facilities = update(state.facilities, { $splice: [[index, 1]] });
      }
    },
    resetFacilitySliceState: (state) => {
      state.facilities = facilitiesInitialState.facilities;
      state.editFacilityName = facilitiesInitialState.editFacilityName;
    },
  },
});

export const { setFacilities, setEditFacilityName, deleteFacility } =
  facilitiesSlice.actions;

const selectSelf = (state: RootState) => state.facilities;

export const selectFacilities = createSelector(
  selectSelf,
  (facilities) => facilities.facilities
);

export const selectEditFacilityName = createSelector(
  selectSelf,
  (facilities) => facilities.editFacilityName
);

export default facilitiesSlice.reducer;
