import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import {components} from "../generated/apiTypes";
import { extractObjectById } from "../utils/object";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { ArrayElement, IMatrixSlice } from "./types";

const matrixInitialState = initialState as IMatrixSlice;

export const matrixSlice = createSlice({
  name: "matrix",
  initialState: {
    matrix: matrixInitialState.matrix,
    blocks: matrixInitialState.blocks,
    selectedMatrixBlock: matrixInitialState.selectedMatrixBlock,
  },
  reducers: {
    setMatrix: (state, action: PayloadAction<IMatrixSlice["matrix"]>) => {
      state.matrix = action.payload;
    },
    setSelectedMatrixBlockId: (
      state,
      action: PayloadAction<IMatrixSlice["selectedMatrixBlock"]["id"]>
    ) => {
      state.selectedMatrixBlock.id = action.payload;
    },
    setSelectedMatrixBlockIndex: (
      state,
      action: PayloadAction<IMatrixSlice["selectedMatrixBlock"]["index"]>
    ) => {
      state.selectedMatrixBlock.index = action.payload;
    },
    setSelectedMatrixBlockInstance: (
      state,
      action: PayloadAction<IMatrixSlice["selectedMatrixBlock"]["instance"]>
    ) => {
      state.selectedMatrixBlock.instance = action.payload;
    },
    setSelectedMatrixBlock: (
      state,
      action: PayloadAction<IMatrixSlice["selectedMatrixBlock"]>
    ) => {
      state.selectedMatrixBlock = action.payload;
    },
    setMatrixBlocks: (state, action: PayloadAction<IMatrixSlice["blocks"]>) => {
      state.blocks = action.payload;
    },
    deleteMatrixBlock: (
      state,
      action: PayloadAction<components["schemas"]["Block"]["id"]>
    ) => {
      const index = state.blocks?.findIndex(({ id }) => action.payload === id);
      if (index !== undefined && index > -1) {
        state.blocks = update(state.blocks, { $splice: [[index, 1]] });
      }
    },
    upMatrixBlock: (
      state,
      action: PayloadAction<components["schemas"]["Block"]["id"]>
    ) => {
      const index = state.blocks?.findIndex(({ id }) => action.payload === id);
      if (index !== undefined && index > -1 && state.blocks) {
        state.blocks = update(
          update(state.blocks, {
            $splice: [[index - 1, 1, state.blocks[index]]],
          }),
          { $splice: [[index, 1, state.blocks[index - 1]]] }
        );
      }
    },
    downMatrixBlock: (
      state,
      action: PayloadAction<components["schemas"]["Block"]["id"]>
    ) => {
      const index = state.blocks?.findIndex(({ id }) => action.payload === id);
      if (index !== undefined && index > -1 && state.blocks) {
        state.blocks = update(
          update(state.blocks, {
            $splice: [[index + 1, 1, state.blocks[index]]],
          }),
          { $splice: [[index, 1, state.blocks[index + 1]]] }
        );
      }
    },
    updateMatrixBlock: (
      state,
      action: PayloadAction<components["schemas"]["Block"]>
    ) => {
      const index = state.blocks?.findIndex(
        ({ id }) => action.payload.id === id
      );
      if (index !== undefined && index > -1) {
        state.blocks = update(state.blocks, {
          [index]: { $set: action.payload },
        });
      }
    },
    resetMatrixSliceState: (state) => {
      state.matrix = matrixInitialState.matrix;
      state.blocks = matrixInitialState.blocks;
      state.selectedMatrixBlock = matrixInitialState.selectedMatrixBlock;
    },
  },
});

export const {
  setMatrix,
  setMatrixBlocks,
  setSelectedMatrixBlockId,
  setSelectedMatrixBlockIndex,
  setSelectedMatrixBlockInstance,
  setSelectedMatrixBlock,
  deleteMatrixBlock,
  upMatrixBlock,
  downMatrixBlock,
  updateMatrixBlock,
  resetMatrixSliceState,
} = matrixSlice.actions;

type SelectMatrixBlockById = {
  [key: number]: ArrayElement<IMatrixSlice["blocks"]>;
};

const selectSelf = (state: RootState) => state.matrix;

export const selectMatrix = createSelector(
  selectSelf,
  (matrix) => matrix.matrix
);

export const selectMatrixBlocks = createSelector(
  selectSelf,
  (matrix) => matrix.blocks
);

export const selectSelectedMatrixBlock = createSelector(
  selectSelf,
  (matrix) => matrix.selectedMatrixBlock
);

export const selectMatrixBlockById = createSelector(selectSelf, (matrix) =>
  matrix.blocks?.reduce<SelectMatrixBlockById>(extractObjectById, {})
);

export default matrixSlice.reducer;
