import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ErrorIcon from "../../images/components/dialog-window/icn-error";
import CloseIcon from "../../images/icn-close";
import { selectShowDialog, setApiError } from "../../store/commonSlice";
import Button from "../Button";

import "./style.scss";

interface IDeleteConfirmationWindowProps {
  closeWindow(): void;
}

const DeleteConfirmationWindow: React.FC<IDeleteConfirmationWindowProps> = ({  closeWindow,
}) => {
  const dispatch = useDispatch();
  const showDialog = useSelector(selectShowDialog);

  const titleByObjectType = () => {
    switch (showDialog?.objectType) {
      case "FILE":
        return "загрузки файла";
      default:
        return "";
    }
  };

  const resetError = () => {
    dispatch(
      setApiError({
        isError: false,
        status: undefined,
        timeStamp: undefined,
        message: undefined,
      })
    );
    closeWindow();
  };

  return (
    <div className="dialog-window__background">
      <div className="dialog-window__container">
        <div className="dialog-window__title-wrapper">
          <button
            type="button"
            className="dialog-window__close-button"
            onClick={resetError}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window__title">
            Ошибка {titleByObjectType()}
          </div>
        </div>
        <div className="dialog-window__wrapper">
          <div className="dialog-window__with-icon-wrapper">
            <div className="dialog-window__icon-wrapper">
            <ErrorIcon />
            </div>
            <div className="dialog-window__message">
              {`«${showDialog?.details}»`}
            </div>
          </div>
          <div className="dialog-window__buttons-wrapper">
            <Button className="dialog-window__button" onClick={resetError}>
              Ok
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
