import * as React from "react";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";

import PopupMenuLayout from "../../../components/PopupMenuLayout/popupMenuLayout";
import User from "../../../images/user";
import { handleStorage } from "../../../services/handleStorage";
import updateAuthToken from "../../../utils/updateAuthToken";

import "./style.scss";

const Logout: React.FC = () => {
  const [activeMenu, setActiveMenu] = React.useState<boolean>(false);
  const controlRef = React.useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const username = handleStorage.getUserName();

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const closeMenu = () => {
    setActiveMenu(false);
  };

  const onMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    toggleMenu();
  };

  const logout = () => {
    handleStorage.removeToken();
    handleStorage.removeUsername();
    updateAuthToken(false);
    navigate("/login");
  };

  return (
    <div className="user-logout__container">
      {username}
      <button
        className={classNames("user-logout__button", activeMenu && "active")}
        ref={controlRef}
        onClick={onMenuClick}
      >
        <User />
      </button>
      {activeMenu && (
        <PopupMenuLayout
          className="user-logout__popup-menu"
          closeMenu={closeMenu}
          controlRef={controlRef}
        >
          {/*<div className="user-logout__popup-menu-triangle" />*/}
          <button
            type="button"
            className="popup-menu-item"
            onClick={() => {
              logout();
              closeMenu();
            }}
          >
            Выйти
          </button>
        </PopupMenuLayout>
      )}
    </div>
  );
};

export default Logout;
