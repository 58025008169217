import React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import {
  selectSelectedMethod,
  setSelectedMethodId,
  setSelectedMethodInstance,
} from "../../../../store/methodSlice";

import "./style.scss";

interface IBlock {
  id: number;
  name?: string;
}

const Article: React.FC<IBlock> = ({ id, name }) => {
  const dispatch = useDispatch();
  const selectedMethod = useSelector(selectSelectedMethod);

  const onBlockClick = () => {
    dispatch(setSelectedMethodId(id));
    dispatch(setSelectedMethodInstance(undefined));
  };

  return (
    <button
      key={id}
      className={classNames(
        "block__container",
        selectedMethod?.id === id && "selected"
      )}
      onClick={onBlockClick}
    >
      <span className="block__title">{name}</span>
    </button>
  );
};

export default Article;
