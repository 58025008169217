import React from "react";
import { useSelector } from "react-redux";
import { PropsValue } from "react-select";

import Dropdown from "../../../../components/Dropdown";
import { MyOptionType } from "../../../../components/Dropdown/handleStyles";
import Input from "../../../../components/Input";
import TextArea from "../../../../components/TextArea/textarea";
import { selectSelectedMatrixBlock } from "../../../../store/matrixSlice";

import "./style.scss";

const Parameters: React.FC = () => {
  const selectedBlock = useSelector(selectSelectedMatrixBlock);

  return (
    <div className="facility-block-details__parameters-container">
      <div className="facility-block-details__parameters-title-row">
        Параметры
      </div>
      <div className="facility-block-details__parameters-panel">
        <div className="block-parameters-row">
          <div className="block-parameters-element">
            <Input
              label="Название"
              value={selectedBlock?.instance?.name}
              disabled
            />
          </div>
          <div className="block-parameters-element">
            <Input
              label="Код (ГенПлан)"
              value={selectedBlock?.instance?.abbreviation}
              disabled
            />
          </div>
        </div>
        <div className="block-parameters-row">
          <div className="block-parameters-element">
            <Dropdown
              placeholder=""
              label="Тип"
              isSearchable={false}
              value={
                {
                  value: selectedBlock?.instance?.block_type,
                  label: selectedBlock?.instance?.block_type,
                } as PropsValue<MyOptionType>
              }
              isDisabled={true}
            />
          </div>
          <div className="block-parameters-element">
            <Dropdown
              placeholder=""
              label="Класс"
              isSearchable={false}
              value={
                {
                  value:
                    selectedBlock?.instance?.equipment_class?.id?.toString(),
                  label: selectedBlock?.instance?.equipment_class?.name,
                } as PropsValue<MyOptionType>
              }
              isDisabled={true}
            />
          </div>
        </div>
        <div className="block-parameters-row">
          <TextArea
            label="Функция блока"
            placeholder="Описание функции"
            value={selectedBlock?.instance?.description}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default Parameters;
