import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedBlock } from "../../../store/blocksSlice";
import {
  selectApiError,
  setApiError,
  setShowDialog,
} from "../../../store/commonSlice";

const useCleanup = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(setSelectedBlock({}));
      dispatch(
        setShowDialog({
          objectType: undefined,
          dialogType: undefined,
          objectName: undefined,
          details: undefined,
        })
      );
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          key: undefined,
          timeStamp: undefined,
        })
      );
    };
  }, []);
};

export default useCleanup;
