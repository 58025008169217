import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import classNames from "classnames";

import {components} from "../../../../generated/apiTypes";
import DownArrowIcon from "../../../../images/icn-sort-down";
import {
  selectParameterToSave,
  selectSelectedClass,
  setSelectedClass,
} from "../../../../store/equipmentSlice";
import {
  sortParamsAscByColumnKey,
  sortParamsDescByColumnKey,
} from "../../../../utils/dynamicSort";
import { IThead, ITheadFormatted } from "./mock";

import "./style.scss";

interface ITableHeadProps {
  columnHeaders: components["schemas"]["ColumnHeader"][];
  tableHeadFormatted: ITheadFormatted;
  tableHead: IThead[];
}

const TableHead: React.FC<ITableHeadProps> = ({
  columnHeaders,
  tableHead,
  tableHeadFormatted,
}) => {
  const dispatch = useDispatch();
  const selectedClass = useSelector(selectSelectedClass);
  const parameterToSave = useSelector(selectParameterToSave);
  const [sorting, setSorting] = React.useState<{
    column: string;
    direction: "ASC" | "DESC";
    numerical: boolean;
  }>();

  React.useEffect(() => {
    if (
      parameterToSave?.parameter.column_key === sorting?.column &&
      parameterToSave?.saved &&
      sorting?.direction === "ASC"
    ) {
      onAscSort(sorting?.column, sorting?.numerical);
    } else if (sorting?.direction === "DESC") {
      onDescSort(sorting?.column, sorting?.numerical);
    }
  }, [parameterToSave?.saved]);

  const getTableHead = (headKey: string) => {
    if (tableHeadFormatted[headKey]?.component)
      return tableHeadFormatted[headKey]?.component;
    if (tableHeadFormatted[headKey]?.title)
      return tableHeadFormatted[headKey]?.title;
    const preDefinedTitle = tableHead.find(
      (item) => item.key === headKey
    )?.name;
    if (preDefinedTitle) return preDefinedTitle;
    return "";
  };

  const onAscSort = (columnKey: string, numerical: boolean) => {
    if (selectedClass?.equipment) {
      const equipmentToSort = [...selectedClass?.equipment];
      equipmentToSort?.sort(sortParamsAscByColumnKey(columnKey, numerical));
      if (selectedClass) {
        dispatch(
          setSelectedClass({ ...selectedClass, equipment: equipmentToSort })
        );
        setSorting({ column: columnKey, direction: "ASC", numerical });
      }
    }
  };

  const onDescSort = (columnKey: string, numerical: boolean) => {
    if (selectedClass?.equipment) {
      const equipmentToSort = [...selectedClass?.equipment];
      equipmentToSort?.sort(sortParamsDescByColumnKey(columnKey, numerical));
      if (selectedClass) {
        dispatch(
          setSelectedClass({ ...selectedClass, equipment: equipmentToSort })
        );
        setSorting({ column: columnKey, direction: "DESC", numerical });
      }
    }
  };

  return (
    <thead>
      <tr className="table-head__row">
        {columnHeaders?.map((item) => {
          const title: string | React.ReactNode = getTableHead(item.key);
          return (
            <th key={item.key} className={classNames("table-head__cell")}>
              <div className="table-head__cell-title-wrapper">
                <div className="cell__title">{title}</div>
                <div className="cell__sort-icons-wrapper">
                  <button
                    className="cell__sort-icn-btn"
                    onClick={() =>
                      onAscSort(item.key, item.value_type !== "string")
                    }
                  >
                    <DownArrowIcon
                      className={classNames(
                        "cell__sort-icn",
                        "up-icn",
                        item.key === sorting?.column &&
                          sorting?.direction === "ASC" &&
                          "selected"
                      )}
                    />
                  </button>
                  <button
                    className="cell__sort-icn-btn"
                    onClick={() =>
                      onDescSort(item.key, item.value_type !== "string")
                    }
                  >
                    <DownArrowIcon
                      className={classNames(
                        "cell__sort-icn",
                        item.key === sorting?.column &&
                          sorting?.direction === "DESC" &&
                          "selected"
                      )}
                    />
                  </button>
                </div>
              </div>
            </th>
          );
        })}
        <th
          key={"delete-th"}
          className={classNames("table-head__cell delete-cell-th")}
        />
      </tr>
    </thead>
  );
};

export default TableHead;
