import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={12}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 2.5h-1v-1a1.08 1.08 0 00-1-1h-3a1.08 1.08 0 00-1 1v1h-3v1h1v7a1.08 1.08 0 001 1h7a1.08 1.08 0 001-1v-7h1v-1h-2zm-4-1h2v1h-3v-1h1zm4 3v6h-1v-6h-1v6h-1v-6h-1v6h-1v-6h-1v6h-1v-7h7v1z"
        fill="#999"
      />
    </svg>
  );
};

export default Icon;
