import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectApiError, setShowDialog } from "../../../store/commonSlice";

const useApiErrorShowDialog = () => {
  const dispatch = useDispatch();
  const apiError = useSelector(selectApiError);

  React.useEffect(() => {
    if (
      (apiError.message as string)?.includes(
        "Expected at least one connection point"
      )
    ) {
      dispatch(
        setShowDialog({
          objectType: "FILE",
          dialogType: "ERROR",
          details: apiError.message as string,
        })
      );
    }
  }, [apiError]);

  return { apiError };
};

export default useApiErrorShowDialog;
