import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ApiErrorUI from '../../components/ApiErrorUI/apiError';
import Button from "../../components/Button";
import Logo from "../../images/logo";
import { signInWithPassword } from "../../services/apiRequests";
import { handleStorage } from '../../services/handleStorage';
import { selectApiError, setApiError } from '../../store/commonSlice';
import updateAuthToken from '../../utils/updateAuthToken';
import FormField from './FormField';

import './style.scss';

const Login: React.FC = () => {
  const apiError = useSelector(selectApiError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [passwordError, setPasswordError] = React.useState<boolean>(false);
  const [requestInProgress, setRequestInProgress] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    return () => {
      if (apiError?.isError)
        dispatch(
          setApiError({
            isError: false,
            status: undefined,
            message: undefined,
            key: undefined,
            timeStamp: undefined,
          })
        );
    };
  }, [apiError.key]);

  const tryToLogin = async () => {
    const validateEmail = !!email;
    const validatePassword = !!password;
    if (!validateEmail) setEmailError(true);
    if (!validatePassword) setPasswordError(true);
    if (validateEmail && validatePassword) {
      setRequestInProgress(true);
      try {
        const formData = {
          username: email,
          password: password,
        };
        const {
          data: { access_token: token },
        } = await signInWithPassword(formData);
        if (token) {
          handleStorage.setToken(token);
          handleStorage.setUsername(email);
          updateAuthToken(token);
          navigate("/");
        }
      } catch (err) {
        //
      }
      setRequestInProgress(false);
    }
  };

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault();
    tryToLogin();
  };

  const resetError = (formValue: "password" | "email") => {
    if (emailError && formValue === "email") setEmailError(false);
    if (passwordError && formValue === "password") setPasswordError(false);
    if (["Incorrect username or password"].includes(apiError.message as string))
      dispatch(
        setApiError({
          isError: false,
          status: undefined,
          message: undefined,
          timeStamp: undefined,
        })
      );
  };

  const onChangeHandler = (
    e: React.FormEvent<HTMLInputElement>,
    formValue: "password" | "email"
  ) => {
    resetError(formValue);
    if (formValue === "password") {
      setPassword(e.currentTarget.value);
    } else if (formValue === "email") {
      setEmail(e.currentTarget.value);
    }
  };

  const errorToShow =
    apiError?.isError && apiError.message !== "Incorrect username or password";

  return (
    <>
      {errorToShow ? (
        <ApiErrorUI />
      ) : (
        <div className="login-page__container">
          <form className="login-page__content" onSubmit={submitHandler}>
            <div className="login-page__logo-wrapper">
              <div className="logo-text-wrapper">
                <Logo className="login-page__logo" />
                База знаний
              </div>
            </div>
            <FormField
              onChangeHandler={onChangeHandler}
              formValue="email"
              label="Логин"
              error={emailError}
            />
            <FormField
              onChangeHandler={onChangeHandler}
              formValue="password"
              type="password"
              label="Пароль"
              onEnter={tryToLogin}
              error={passwordError}
            />
            <div className="login-page-button-wrapper">
              <Button
                type="submit"
                className="login-page-button"
                disabled={requestInProgress}
              >
                Войти
              </Button>
            </div>
            {emailError || passwordError ? (
              <>
                {emailError && (
                  <div className="login-page__error-message">Введите логин</div>
                )}
                {passwordError && (
                  <div className="login-page__error-message">
                    Введите пароль
                  </div>
                )}
              </>
            ) : apiError?.message === "Incorrect username or password" ? (
              <div className="login-page__error-message">
                Неверная комбинация логина и пароля
              </div>
            ) : (
              <></>
            )}
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
