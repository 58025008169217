import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SingleValue } from "react-select";

import update from "immutability-helper";

import Dropdown from "../../../../components/Dropdown";
import { MyOptionType } from "../../../../components/Dropdown/handleStyles";
import { components } from "../../../../generated/apiTypes";
import { updateInConnectionPointById } from "../../../../services/apiRequests";
import {
  selectSelectedBlock,
  setSelectedBlockInstance,
  updateBlock,
} from "../../../../store/blocksSlice";
import {
  selectGidratOptions,
  selectMolarFlowOptions,
  selectParafinOptions,
  selectPressureOptions,
  selectTemperatureOptions,
  selectTypesOptions,
} from "../../../../store/optionsSlice";

import "./style.scss";

interface IInputPointProps {
  connectionPoint: components["schemas"]["InputConnectionPoint"];
}

const InputPoint: React.FC<IInputPointProps> = ({ connectionPoint }) => {
  const dispatch = useDispatch();
  const gidratOptions = useSelector(selectGidratOptions);
  const parafinOptions = useSelector(selectParafinOptions);
  const temperatureOptions = useSelector(selectTemperatureOptions);
  const pressureOptions = useSelector(selectPressureOptions);
  const typesOptions = useSelector(selectTypesOptions);
  const molarFlowOptions = useSelector(selectMolarFlowOptions);
  const selectedBlock = useSelector(selectSelectedBlock);

  const onOptionChange = async (
    option: SingleValue<MyOptionType>,
    param:
      | "type"
      | "temperature"
      | "pressure"
      | "molar_flow"
      | "temperature_gidrat"
      | "temperature_parafin"
  ) => {
    if (option?.value && selectedBlock.instance && selectedBlock.id) {
      try {
        const index = selectedBlock.instance?.input_connection_points.findIndex(
          ({ id }) => connectionPoint.id === id
        );
        if (index !== undefined && index > -1) {
          const pointsToUpdate = update(
            selectedBlock.instance?.input_connection_points,
            {
              [index]: {
                $set: {
                  ...selectedBlock.instance?.input_connection_points[index],
                  [param]: option?.value,
                },
              },
            }
          );
          const objToUpdate = {
            ...selectedBlock.instance,
            input_connection_points: pointsToUpdate,
          };
          dispatch(setSelectedBlockInstance(objToUpdate));
          await updateInConnectionPointById(
            selectedBlock.id.toString(),
            connectionPoint.id.toString(),
            { [param]: option?.value }
          );
          dispatch(updateBlock(objToUpdate));
        }
      } catch (e) {
        //
      }
    }
  };

  return (
    <div key={connectionPoint?.id} className="connection-points__point">
      <div className="connection-points__sub-title">
        {connectionPoint?.name}
      </div>
      <div className="block-parameters-row">
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Тип"
            options={typesOptions}
            value={typesOptions?.find(
              (item) => item.value === connectionPoint?.type
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "type")}
          />
        </div>
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Температура, К"
            options={temperatureOptions}
            value={temperatureOptions?.find(
              (item) => item.value === connectionPoint?.temperature
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "temperature")}
          />
        </div>
      </div>
      <div className="block-parameters-row">
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Давление, Pa"
            options={pressureOptions}
            value={pressureOptions?.find(
              (item) => item.value === connectionPoint?.pressure
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "pressure")}
          />
        </div>
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Производительность, моль"
            options={molarFlowOptions}
            value={molarFlowOptions?.find(
              (item) => item.value === connectionPoint?.molar_flow
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "molar_flow")}
          />
        </div>
      </div>
      <div className="block-parameters-row">
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Температура гидратообразования, К"
            options={gidratOptions}
            value={gidratOptions?.find(
              (item) => item.value === connectionPoint?.temperature_gidrat
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "temperature_gidrat")}
          />
        </div>
        <div className="block-parameters-element">
          <Dropdown
            placeholder=""
            label="Температура парафинообразования, К"
            options={parafinOptions}
            value={parafinOptions?.find(
              (item) => item.value === connectionPoint?.temperature_parafin
            )}
            isSearchable={false}
            onChange={(e) => onOptionChange(e, "temperature_parafin")}
          />
        </div>
      </div>
    </div>
  );
};

export default InputPoint;
