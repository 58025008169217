import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { RootState } from "./store";
import { IOptionsSlice } from "./types";

const optionsInitialState = initialState as IOptionsSlice;

export const optionsSlice = createSlice({
  name: "options",
  initialState: {
    in_cp: optionsInitialState.in_cp,
    blockTypesOptions: optionsInitialState.blockTypesOptions,
    equipmentClassesOptions: optionsInitialState.equipmentClassesOptions,
    abbreviationsOptions: optionsInitialState.abbreviationsOptions,
    hints: optionsInitialState.hints,
  },
  reducers: {
    setGidratOptions: (
      state,
      action: PayloadAction<IOptionsSlice["in_cp"]["gidratOptions"]>
    ) => {
      state.in_cp.gidratOptions = action.payload;
    },
    setParafinOptions: (
      state,
      action: PayloadAction<IOptionsSlice["in_cp"]["parafinOptions"]>
    ) => {
      state.in_cp.parafinOptions = action.payload;
    },
    setTypesOptions: (
      state,
      action: PayloadAction<IOptionsSlice["in_cp"]["typesOptions"]>
    ) => {
      state.in_cp.typesOptions = action.payload;
    },
    setTemperatureOptions: (
      state,
      action: PayloadAction<IOptionsSlice["in_cp"]["temperatureOptions"]>
    ) => {
      state.in_cp.temperatureOptions = action.payload;
    },
    setPressureOptions: (
      state,
      action: PayloadAction<IOptionsSlice["in_cp"]["pressureOptions"]>
    ) => {
      state.in_cp.pressureOptions = action.payload;
    },
    setMolarFlowOptions: (
      state,
      action: PayloadAction<IOptionsSlice["in_cp"]["molarFlowOptions"]>
    ) => {
      state.in_cp.molarFlowOptions = action.payload;
    },
    setBlockTypesOptions: (
      state,
      action: PayloadAction<IOptionsSlice["blockTypesOptions"]>
    ) => {
      state.blockTypesOptions = action.payload;
    },
    setEquipmentClassesOptions: (
      state,
      action: PayloadAction<IOptionsSlice["equipmentClassesOptions"]>
    ) => {
      state.equipmentClassesOptions = action.payload;
    },
    setAbbreviationsOptions: (
      state,
      action: PayloadAction<IOptionsSlice["abbreviationsOptions"]>
    ) => {
      state.abbreviationsOptions = action.payload;
    },
    setHints: (state, action: PayloadAction<IOptionsSlice["hints"]>) => {
      state.hints = action.payload;
    },
    resetOptionsSliceState: (state) => {
      state.in_cp = optionsInitialState.in_cp;
      state.blockTypesOptions = optionsInitialState.blockTypesOptions;
      state.equipmentClassesOptions =
        optionsInitialState.equipmentClassesOptions;
      state.abbreviationsOptions = optionsInitialState.abbreviationsOptions;
      state.hints = optionsInitialState.hints;
    },
  },
});

export const {
  setGidratOptions,
  setParafinOptions,
  setMolarFlowOptions,
  setPressureOptions,
  setTemperatureOptions,
  setTypesOptions,
  setBlockTypesOptions,
  setEquipmentClassesOptions,
  setAbbreviationsOptions,
  setHints,
} = optionsSlice.actions;

const selectSelf = (state: RootState) => state.options;

export const selectGidratOptions = createSelector(
  selectSelf,
  (options) => options.in_cp.gidratOptions
);

export const selectParafinOptions = createSelector(
  selectSelf,
  (options) => options.in_cp.parafinOptions
);

export const selectTypesOptions = createSelector(
  selectSelf,
  (options) => options.in_cp.typesOptions
);

export const selectTemperatureOptions = createSelector(
  selectSelf,
  (options) => options.in_cp.temperatureOptions
);

export const selectPressureOptions = createSelector(
  selectSelf,
  (options) => options.in_cp.pressureOptions
);

export const selectMolarFlowOptions = createSelector(
  selectSelf,
  (options) => options.in_cp.molarFlowOptions
);

export const selectBlockTypesOptions = createSelector(
  selectSelf,
  (options) => options.blockTypesOptions
);

export const selectAbbreviationsOptions = createSelector(
  selectSelf,
  (options) => options.abbreviationsOptions
);

export const selectEquipmentClassesOptions = createSelector(
  selectSelf,
  (options) => options.equipmentClassesOptions
);

export const selectHints = createSelector(
  selectSelf,
  (options) => options.hints
);

export default optionsSlice.reducer;
