import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { components } from "../generated/apiTypes";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { IConnectionsSlice } from "./types";

const connectionsInitialState = initialState as IConnectionsSlice;

export const connectionsSlice = createSlice({
  name: "connections",
  initialState: {
    panelIsShown: connectionsInitialState.panelIsShown,
    connectionPoint: connectionsInitialState.connectionPoint,
    connections: connectionsInitialState.connections,
    availableBlocks: connectionsInitialState.availableBlocks,
    connectionsBlocks: connectionsInitialState.connectionsBlocks,
  },
  reducers: {
    setPanelIsShown: (
      state,
      action: PayloadAction<IConnectionsSlice["panelIsShown"]>
    ) => {
      state.panelIsShown = action.payload;
    },
    setConnectionPoint: (
      state,
      action: PayloadAction<IConnectionsSlice["connectionPoint"]>
    ) => {
      state.connectionPoint = action.payload;
    },
    setConnections: (
      state,
      action: PayloadAction<IConnectionsSlice["connections"]>
    ) => {
      state.connections = action.payload;
    },
    setAvailableBlocks: (
      state,
      action: PayloadAction<IConnectionsSlice["availableBlocks"]>
    ) => {
      state.availableBlocks = action.payload;
    },
    setConnectionsBlocks: (
      state,
      action: PayloadAction<IConnectionsSlice["connectionsBlocks"]>
    ) => {
      state.connectionsBlocks = action.payload;
    },
    updateConnectionsBlocks: (
      state,
      action: PayloadAction<{
        id: number;
        block: components["schemas"]["BlockShort"];
      }>
    ) => {
      const index = state.connectionsBlocks?.findIndex(
        ({ id }) => action.payload.id === id
      );
      if (index !== undefined && index > -1) {
        state.connectionsBlocks = update(state.connectionsBlocks, {
          [index]: { $set: action.payload },
        });
      } else {
        state.connectionsBlocks = update(state.connectionsBlocks, {
          $push: [action.payload],
        });
      }
    },
    deleteConnectionsBlock: (
      state,
      action: PayloadAction<number>
    ) => {
      const index = state.connectionsBlocks?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1) {
        state.connectionsBlocks = update(state.connectionsBlocks, {
          $splice: [[index, 1]],
        });
      }
    },
    updateConnection: (
      state,
      action: PayloadAction<components["schemas"]["Connection"]>
    ) => {
      const index = state.connections?.findIndex(
        ({ id }) => action.payload.id === id
      );
      if (index !== undefined && index > -1) {
        state.connections = update(state.connections, {
          [index]: { $set: action.payload },
        });
      }
    },
    upConnection: (
      state,
      action: PayloadAction<components["schemas"]["Connection"]["id"]>
    ) => {
      const index = state.connections?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1 && state.connections) {
        state.connections = update(
          update(state.connections, {
            $splice: [[index - 1, 1, state.connections[index]]],
          }),
          { $splice: [[index, 1, state.connections[index - 1]]] }
        );
      }
    },
    downConnection: (
      state,
      action: PayloadAction<components["schemas"]["Connection"]["id"]>
    ) => {
      const index = state.connections?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1 && state.connections) {
        state.connections = update(
          update(state.connections, {
            $splice: [[index + 1, 1, state.connections[index]]],
          }),
          { $splice: [[index, 1, state.connections[index + 1]]] }
        );
      }
    },
    deleteConnection: (
      state,
      action: PayloadAction<components["schemas"]["Connection"]["id"]>
    ) => {
      const index = state.connections?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1) {
        state.connections = update(state.connections, {
          $splice: [[index, 1]],
        });
      }
    },
    resetConnectionsSliceState: (state) => {
      state.panelIsShown = connectionsInitialState.panelIsShown;
      state.connectionPoint = connectionsInitialState.connectionPoint;
      state.connections = connectionsInitialState.connections;
      state.availableBlocks = connectionsInitialState.availableBlocks;
      state.connectionsBlocks = connectionsInitialState.connectionsBlocks;
    },
  },
});

export const {
  setPanelIsShown,
  setConnectionPoint,
  setConnections,
  setAvailableBlocks,
  setConnectionsBlocks,
  deleteConnectionsBlock,
  upConnection,
  downConnection,
  updateConnectionsBlocks,
  updateConnection,
  deleteConnection,
  resetConnectionsSliceState,
} = connectionsSlice.actions;

const selectSelf = (state: RootState) => state.connections;

export const selectPanelIsShown = createSelector(
  selectSelf,
  (connections) => connections.panelIsShown
);

export const selectConnectionPoint = createSelector(
  selectSelf,
  (connections) => connections.connectionPoint
);

export const selectConnections = createSelector(
  selectSelf,
  (connections) => connections.connections
);

export const selectAvailableBlocks = createSelector(
  selectSelf,
  (connections) => connections.availableBlocks
);

export const selectConnectionsBlocks = createSelector(
  selectSelf,
  (connections) => connections.connectionsBlocks
);

export default connectionsSlice.reducer;
