import React from "react";

import { components } from "../../../generated/apiTypes";
import { getFacilityTemplates } from "../../../services/apiRequests";
import RadioButton from "../../RadioButton";

import "./style.scss";

interface IVersionOptionsProps {
  option?: components["schemas"]["FacilityCreate"];
  setOption(option: components["schemas"]["FacilityCreate"]): void;
}

const FacilityTypeOptions: React.FC<IVersionOptionsProps> = ({
  option,
  setOption,
}) => {
  const [templates, setTemplates] = React.useState<
    components["schemas"]["SchemaType"][] | { name: string }[]
  >();

  const fetchTemplates = async () => {
    const response = await getFacilityTemplates();
    if (response.data)
      setTemplates([{ name: "Пустой объект" }, ...response.data]);
  };

  React.useEffect(() => {
    fetchTemplates();
    setValue('');
  }, []);

  const setValue = (id: string) => {
    const optionId = parseInt(id);
    if (optionId)
      setOption({
        schema_type_id: optionId,
        name: (templates as components["schemas"]["SchemaType"][])?.find(
          (item) => item.id === optionId
        )?.name as string,
      });
    else
      setOption({
        schema_type_id: undefined,
        name: "",
      });
  };

  return (
    <>
      <div className="option__scrollable">
        {templates?.map((item) => (
          <div className="option__container">
            <RadioButton
              id={`facility-radio-${item.name}`}
              value={(
                item as components["schemas"]["SchemaType"]
              )?.id?.toString()}
              label={item.name}
              checked={
                option?.schema_type_id ===
                (item as components["schemas"]["SchemaType"])?.id
              }
              setValue={setValue}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default FacilityTypeOptions;
