import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { initialState } from "./initialState";
import { RootState } from "./store";
import { IErrorsSlice } from "./types";

const errorsInitialState = initialState as IErrorsSlice;

export const errorsSlice = createSlice({
  name: "errors",
  initialState: {
    errors: errorsInitialState.errors,
  },
  reducers: {
    setErrors: (state, action: PayloadAction<IErrorsSlice["errors"]>) => {
      state.errors = action.payload;
    },
    resetErrorsSliceState: (state) => {
      state.errors = errorsInitialState.errors;
    },
  },
});

export const { resetErrorsSliceState, setErrors } = errorsSlice.actions;

const selectSelf = (state: RootState) => state.errors;

export const selectErrors = createSelector(
  selectSelf,
  (errors) => errors.errors
);

export default errorsSlice.reducer;
