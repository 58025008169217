import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  selectMatrixBlockById,
  selectSelectedMatrixBlock,
  setSelectedMatrixBlockInstance
} from "../../../store/matrixSlice";

const useSelectedMatrixBlock = () => {
  const dispatch = useDispatch();
  const selectedBlock = useSelector(selectSelectedMatrixBlock);
  const blockById = useSelector(selectMatrixBlockById);

  const extractBlockById = () => {
    if (selectedBlock?.id !== undefined && blockById) {
      dispatch(setSelectedMatrixBlockInstance(undefined));
      dispatch(setSelectedMatrixBlockInstance(blockById[selectedBlock.id]));
    }
  };

  React.useEffect(() => {
    extractBlockById();
  }, [
    selectedBlock?.id,
    blockById && selectedBlock.id && blockById[selectedBlock.id],
  ]);

  return { extractBlockById };
};

export default useSelectedMatrixBlock;
