import * as React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import ApiErrorUI from "../../components/ApiErrorUI/apiError";
import Layout from "../../components/DatabasePageLayout/layout";
import DialogWindow from "../../components/DialogWindow";
import { selectSelectedBlock } from "../../store/blocksSlice";
import { selectApiError } from "../../store/commonSlice";
import BlocksTree from "./components/BlocksTree/blocksTree";
import ConnectionPoints from "./components/ConnectionPoints/connectionPoints";
import Parameters from "./components/Parameters/parameters";
import useApiErrorShowDialog from "./hooks/use-api-error-show-dialog";
import useCleanup from "./hooks/use-cleanup";
import useOptions from "./hooks/use-options";
import useSelectedBlock from "./hooks/use-selected-block";

import "./style.scss";

const Blocks: React.FC = () => {
  useOptions();
  useCleanup();
  useSelectedBlock();
  useApiErrorShowDialog();
  const apiError = useSelector(selectApiError);
  const selectedBlock = useSelector(selectSelectedBlock);

  const errorToShow =
    apiError?.isError &&
    !(apiError.message as string)?.includes(
      "Expected at least one connection point"
    ) &&
    !(
      apiError.status === 422 &&
      (apiError.message as { loc: any; msg: string; type: string }[])?.filter(
        (item) => item.type === "value_error"
      )
    );

  return (
    <>
      <Layout pageTitle="База блоков">
        <DialogWindow />
        {errorToShow ? (
          <ApiErrorUI />
        ) : (
          <div
            className={classNames(
              "blocks__container",
              selectedBlock?.id && "separated-container"
            )}
          >
            <BlocksTree />
            <div className="blocks__scrollable-panel">
              <Parameters />
              <ConnectionPoints />
              <div className="blocks__right-panel" />
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};

export default Blocks;
