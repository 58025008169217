export const handleStorage = {
  setToken: (token: string) => {
    return localStorage.setItem('accessToken', token);
  },
  getToken: () => {
    return localStorage.getItem('accessToken');
  },
  removeToken: () => {
    localStorage.removeItem('accessToken');
  },
  setUsername: (name: string) => {
    return localStorage.setItem('username', name);
  },
  getUserName: () => {
    return localStorage.getItem('username');
  },
  removeUsername: () => {
    localStorage.removeItem('username');
  },
};
