import * as React from "react";

const Arrow = (props: any) => {
  return (
    <svg
      width={24}
      height={26}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.293 6.013a.972.972 0 011.414 0l6 6.25c.39.407.39 1.067 0 1.474l-6 6.25a.972.972 0 01-1.414 0 1.073 1.073 0 010-1.474L21.586 13l-5.293-5.513a1.073 1.073 0 010-1.474z"
        fill="#CCCDCD"
      />
    </svg>
  );
};

export default Arrow;
