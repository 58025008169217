import { IStore } from './types';

export const initialState: IStore = {
  auth: false,
  apiError: { isError: false },
  showDialog: {},
  selectedBlock: {},
  blocks: undefined,
  in_cp: {
    gidratOptions: [],
    parafinOptions: [],
    typesOptions: [],
    temperatureOptions: [],
    pressureOptions: [],
    molarFlowOptions: [],
  },
  blockTypesOptions: [],
  equipmentClassesOptions: [],
  abbreviationsOptions: [],
  hints: [],
  panelIsShown: false,
  connectionsBlocks: [],
  facilities: undefined,
  editFacilityName: false,
  facility: undefined,
  version: undefined,
  matrix: undefined,
  selectedMatrixBlock: {},
  tableHeads: [],
  equipmentClasses: [],
  allEquipment: [],
  methods: undefined,
  selectedMethod: {},
  errors: undefined,
};
