import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAvailableBlocksToConnectByCpId } from "../../../services/apiRequests";
import {selectConnectionPoint, setAvailableBlocks} from "../../../store/connectionsSlice";
import {selectMatrixBlocks} from "../../../store/matrixSlice";

const useConnections = () => {
  const dispatch = useDispatch();
  const selectedConnectionPoint = useSelector(selectConnectionPoint);
  const matrixBlocks = useSelector(selectMatrixBlocks);

  const fetchPoints = async () => {
    if (selectedConnectionPoint?.id !== undefined) {
      try {
        const response = await getAvailableBlocksToConnectByCpId(
          selectedConnectionPoint?.id.toString()
        );
        if (response?.data) {
          dispatch(setAvailableBlocks(response.data));
        }
      } catch (e) {
        //
      }
    }
  };

  React.useEffect(() => {
    if (selectedConnectionPoint?.type === "OUT") fetchPoints();
  }, [selectedConnectionPoint?.id, matrixBlocks?.length]);

  return;
};

export default useConnections;
