import React, { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

import classnames from "classnames";
import update from "immutability-helper";

import Input from "../../../../components/Input";
import { components } from "../../../../generated/apiTypes";
import DeleteIcon from "../../../../images/icn-delete";
import {createEquipmentById, updateEquipmentById} from "../../../../services/apiRequests";
import { setShowDialog } from "../../../../store/commonSlice";
import {
  selectAllEquipment,
  selectParameterToSave, selectSelectedClass,
  setParameterToSave, setSelectedClass,
  updateEquipment, updateSelectedClassEquipment,
} from "../../../../store/equipmentSlice";
import { isNumber, smartFormat } from "../../../../utils/number";

export interface ITableRowProps {
  equipmentId: number;
  valuesRow: components["schemas"]["Parameter"][];
  columnHeaders: components["schemas"]["ColumnHeader"][];
  classname?: string;
}

const TableRow: React.FC<ITableRowProps> = ({
  equipmentId,
  valuesRow,
  columnHeaders,
  classname,
}) => {
  const dispatch = useDispatch();
  const selectedClass = useSelector(selectSelectedClass);
  const parameterToSave = useSelector(selectParameterToSave);
  const [createCellInProgress, setCreateCellInProgress] = React.useState<boolean>(false);

  const onDeleteClick = () => {
    dispatch(
      setShowDialog({
        objectName: "",
        objectType: "EQUIPMENT_ROW",
        dialogType: "DELETE_CONFIRM",
        objectId: equipmentId.toString(),
      })
    );
  };

  const onChangeValue = async (
    e: ChangeEvent<HTMLInputElement>,
    headKey: components["schemas"]["ColumnHeader"]["key"],
    numerical: boolean,
  ) => {
    const digitOnly = new RegExp("^[+]?([0-9]*)*[.,]?([0-9]{0,3})?$", "g");
    if (
      selectedClass &&
      equipmentId &&
      (!numerical || (numerical && e.target.value.match(digitOnly)))
    ) {
      dispatch(
        setParameterToSave({
          id: equipmentId,
          parameter: { column_key: headKey, value: e.target.value },
          saved: false,
        })
      );
      let equipmentToUpdate = selectedClass.equipment?.find(
        (item) => item.id === equipmentId
      );
      const parameterIx = equipmentToUpdate?.params.findIndex(
        (item) => item.column_key === headKey
      );
      if (parameterIx !== undefined && parameterIx > -1 && equipmentToUpdate) {
        equipmentToUpdate = update(equipmentToUpdate, {
          params: {
            [parameterIx]: {
              $set: {
                ...equipmentToUpdate?.params[parameterIx],
                value: e.target.value,
              },
            },
          },
        });
        dispatch(updateSelectedClassEquipment(equipmentToUpdate));
        dispatch(updateEquipment(equipmentToUpdate));
      } else if (!createCellInProgress) {
        try {
          setCreateCellInProgress(true);
          const response = await createEquipmentById(
            equipmentId.toString(),
            headKey
          );
          setCreateCellInProgress(false);
          if (response.data) {
            equipmentToUpdate = update(equipmentToUpdate, {
              params: {
                $push: [
                  {
                    column_key: response.data.column_key,
                    value: e.target.value,
                  },
                ],
              },
            });
            if (equipmentToUpdate) {
              dispatch(updateSelectedClassEquipment(equipmentToUpdate));
              dispatch(updateEquipment(equipmentToUpdate));
            }
          }
        } catch (e) {
          //
        }
      }
    }
  };

  const saveBlockParameter = async () => {
    if (parameterToSave && !createCellInProgress) {
      try {
        await updateEquipmentById(
          parameterToSave.id.toString(),
          parameterToSave.parameter.column_key,
          parameterToSave.parameter.value
        );
        dispatch(setParameterToSave({ ...parameterToSave, saved: true }));
      } catch (e) {
        //
      }
    }
  };

  return (
    <tr key={equipmentId} className={classnames("table-row", classname)}>
      {columnHeaders?.map((column) => {
        const value = valuesRow?.find(
          (item) => item.column_key === column.key
        )?.value;
        return (
          <td className={classnames("table-row__data")} key={column.key}>
            <Input
              containerClassName="hidden-input"
              value={value}
              onChange={(e) =>
                onChangeValue(e, column.key, column.value_type !== "string")
              }
              onBlur={saveBlockParameter}
            />
            {/*value && isNumber(value) ? smartFormat(parseFloat(value)) : value*/}
          </td>
        );
      })}
      <td
        className={classnames("table-row__data delete-cell-td")}
        key={"delete-td"}
      >
        <button className="table-row__delete-btn" onClick={onDeleteClick}>
          <DeleteIcon />
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
