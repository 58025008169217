import * as React from "react";
import { SVGProps } from "react";

const Icon = (props: SVGProps<any>) => {
  return (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6667 7.33334H3.33333C2.59695 7.33334 2 7.9303 2 8.66668V13.3333C2 14.0697 2.59695 14.6667 3.33333 14.6667H12.6667C13.403 14.6667 14 14.0697 14 13.3333V8.66668C14 7.9303 13.403 7.33334 12.6667 7.33334Z"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.66602 7.33334V4.66668C4.66602 3.78262 5.01721 2.93478 5.64233 2.30965C6.26745 1.68453 7.11529 1.33334 7.99935 1.33334C8.8834 1.33334 9.73125 1.68453 10.3564 2.30965C10.9815 2.93478 11.3327 3.78262 11.3327 4.66668V7.33334"
        stroke="#999999"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Icon;
