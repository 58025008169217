import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getConnectionsByCpId } from "../../../services/apiRequests";
import {selectConnectionPoint, setConnections} from "../../../store/connectionsSlice";

const useConnections = () => {
  const dispatch = useDispatch();
  const selectedConnectionPoint = useSelector(selectConnectionPoint);

  const fetchConnections = async () => {
    if (selectedConnectionPoint?.id !== undefined) {
      try {
        let data = {};
        if (selectedConnectionPoint.type === "OUT") {
          data = { outCpId: selectedConnectionPoint.id };
        } else if (selectedConnectionPoint.type === "IN") {
          data = { inputCpId: selectedConnectionPoint.id };
        }
        const response = await getConnectionsByCpId(data);
        if (response?.data) {
          dispatch(setConnections(response.data));
        }
      } catch (e) {
        //
      }
    }
  };

  React.useEffect(() => {
    fetchConnections();
  }, [selectedConnectionPoint?.id]);

  return;
};

export default useConnections;
