import * as React from 'react';

import classnames from 'classnames';

import './style.scss';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'primary' | 'secondary';
}

const Button: React.FC<IButton> = ({
  mode = 'primary',
  className,
  ...props
}) => {
  return (
    <button
      {...props}
      className={classnames('main-button button-text', className, mode)}
    >
      <div className="main-button__icon-wrapper">{props.children}</div>
    </button>
  );
};

export default Button;
