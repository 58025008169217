import * as React from "react";
import Select, { Props } from "react-select";

import DropdownIndicator from "./DropdownIndicator";
import handleStyles, { MyOptionType } from "./handleStyles";

export interface IDropdown extends Props<MyOptionType, false> {
  noIndicator?: boolean;
  error?: boolean;
  label?: string;
  scrollableRef?: React.RefObject<any>;
}

const Dropdown: React.FC<IDropdown> = (props) => {
  const selectRef = React.useRef<any>(null);
  const [scrolled, setScrolled] = React.useState<boolean>(false);

  const onMenuOpen = () => {
    setTimeout(() => {
      const menuEl = selectRef?.current.menuListRef;
      if (menuEl && props.scrollableRef?.current?.clientHeight) {
        const bounding = menuEl.getBoundingClientRect();
        const scrollableBounding =
          props.scrollableRef.current.getBoundingClientRect();
        if (
          bounding.bottom >
          scrollableBounding.top + props.scrollableRef?.current?.clientHeight
        ) {
          setScrolled(true);
          menuEl.scrollIntoView({
            behavior: "smooth",
          });
        }
      } else  if (menuEl) {
        const bounding = menuEl.getBoundingClientRect();
        if (bounding.bottom > window.innerHeight) {
          setScrolled(true);
          menuEl.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    }, 0);
  };

  const onMenuClose = () => {
    setTimeout(() => {
      const controlEl = selectRef?.current.controlRef;
      if (controlEl && scrolled) {
        setScrolled(false);
        controlEl.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 0);
  };

  return (
    <>
      {props.label && <span className="input-label">{props.label}</span>}
      <Select
        {...props}
        ref={selectRef}
        styles={handleStyles}
        components={{
          DropdownIndicator: props.noIndicator ? () => null : DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        noOptionsMessage={() => ""}
        menuShouldScrollIntoView={false}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
      />
    </>
  );
};

export default Dropdown;
