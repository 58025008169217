import React from "react";
import { useSelector } from "react-redux";

import { selectSelectedBlock } from "../../../../store/blocksSlice";
import InputPoints from "./InputPoints";
import OutputPoints from "./OutputPoints";

import "./style.scss";

const ConnectionPoints: React.FC = () => {
  const selectedBlock = useSelector(selectSelectedBlock);

  return (
    <div className="connection-points__container">
      {(selectedBlock?.instance?.input_connection_points &&
        selectedBlock?.instance?.input_connection_points.length > 0) ||
      (selectedBlock?.instance?.out_connection_points &&
        selectedBlock?.instance?.out_connection_points.length > 0) ? (
        <>
          <div className="connection-points__title">Точки подключения</div>
          <InputPoints />
          <OutputPoints />
        </>
      ) : selectedBlock?.id ? (
        <div className="connection-points__empty-message">
          Для определения точек подключения загрузите .svg файл блока
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ConnectionPoints;
