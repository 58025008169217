import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { getBlocksByMatrixId } from "../../../services/apiRequests";
import {
  selectMatrix,
  setMatrixBlocks,
  setSelectedMatrixBlock,
  setSelectedMatrixBlockId, setSelectedMatrixBlockIndex,
  setSelectedMatrixBlockInstance,
} from "../../../store/matrixSlice";

const useMatrixBlocks = () => {
  const dispatch = useDispatch();
  const matrix = useSelector(selectMatrix);

  const fetchBlocks = async () => {
    if (matrix?.id !== undefined) {
      try {
        const response = await getBlocksByMatrixId(matrix?.id.toString());
        if (response?.data) {
          dispatch(setMatrixBlocks(response.data));
          if (response.data.length > 0) {
            dispatch(setSelectedMatrixBlockInstance(response.data[0]));
            dispatch(setSelectedMatrixBlockId(response.data[0].id));
            dispatch(setSelectedMatrixBlockIndex(0));
          }
        }
      } catch (e) {
        //
      }
    }
  };

  React.useEffect(() => {
    dispatch(setSelectedMatrixBlock({}));
    fetchBlocks();
  }, [matrix?.id]);

  return;
};

export default useMatrixBlocks;
