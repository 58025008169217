import { StylesConfig } from "react-select";

import { IDropdown } from "./index";

export type MyOptionType = {
  label: string;
  value: string;
  infoId?: string;
  disabled?: boolean;
};

const handleStyles: StylesConfig<MyOptionType, false> = {
  control: (provided, state) => {
    const backgroundColor = state.isDisabled
      ? "transparent"
      : "var(--input-background)";
    const border = (state.selectProps as IDropdown).error
      ? "1px solid var(--error)"
      : "1px solid var(--input-border)";
    const padding = "7px";
    return {
      ...provided,
      border,
      padding,
      backgroundColor,
      fontWeight: "normal",
      minHeight: "unset",
      transition: "all 0.25s ease",
      boxShadow: "none",
      borderRadius: "4px",
      cursor: "pointer",
      "&:hover": {
        border: (state.selectProps as IDropdown).error
          ? "1px solid #D63854"
          : "1px solid E2E6ED",
        backgroundColor: "var(--input-background-hover)",
      },
      "&:active": {
        backgroundColor: "var(--input-background-active)",
      },
    };
  },
  menuList: (provided) => {
    return {
      ...provided,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },
  option: (provided, state: { isSelected: boolean; isDisabled: boolean }) => {
    const color = state.isSelected
      ? "var(--main-text)"
      : state.isDisabled
      ? "var(--label-text)"
      : "var(--main-text)";
    const backgroundColor = state.isSelected
      ? "var(--popup-item-selected)"
      : "var(--popup-background)";
    const hoverBackgroundColor = state.isSelected
      ? "var(--popup-item-hover)"
      : state.isDisabled
      ? "none"
      : "var(--popup-item-hover)";
    const cursor = state.isDisabled ? "default" : "pointer";
    return {
      ...provided,
      color,
      backgroundColor,
      cursor,
      verticalAlign: "middle",
      transition: "all 0.25s ease",
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      padding: "8px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&:hover": {
        backgroundColor: hoverBackgroundColor,
      },
      "&:disabled": {
        backgroundColor: "pink",
      },
    };
  },
  menu: (provided, state) => {
    return {
      ...provided,
      width: "100%",
      marginTop: 4,
      marginBottom: 0,
      paddingTop: 8,
      paddingBottom: 8,
      borderRadius: 4,
      boxShadow: "0px 2px 8px var(--page-background)",
      backgroundColor: "var(--popup-background)",
      zIndex: 10,
      "& div": {
        'overflow-y': "overlay",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          background: "transparent",
          width: 14,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(62, 62, 62, 0.8)",
          backgroundClip: "content-box",
          border: "4px solid transparent",
          borderRadius: 14,
          minHeight: 30,
        },
        "&::-webkit-scrollbar-track-piece": {
          backgroundColor: "transparent",
          "-webkit-border-radius": "6px",
        },
      },
    };
  },
  indicatorsContainer: (provided) => {
    return {
      ...provided,
      fontSize: 0,
      height: "16px",
      alignSelf: "center",
    };
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      padding: "0",
    };
  },
  clearIndicator: (provided) => {
    return {
      ...provided,
      padding: "0 8px",
    };
  },
  valueContainer: (provided) => {
    return {
      ...provided,
      padding: 0,
      lineHeight: "16px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      flexWrap: "nowrap",
      "& input": { height: "0" },
    };
  },
  input: (provided) => {
    return {
      ...provided,
      color: "var(--main-text)",
      margin: 0,
      paddingBottom: 0,
      paddingTop: 0,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      marginLeft: 0,
      position: "unset",
      transform: "none",
      color: "var(--input-placeholder)",
    };
  },
  singleValue: (provided, state) => {
    const color = state.isDisabled ? "var(--label-text)" : "var(--main-text)";
    return {
      ...provided,
      color,
      marginLeft: 0,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: "16px",
      position: "unset",
      transform: "none",
      marginRight: "12px",
    };
  },
};

export default handleStyles;
