import { configureStore } from '@reduxjs/toolkit';

import blocksSlice from "./blocksSlice";
import commonSlice from "./commonSlice";
import connectionsSlice from "./connectionsSlice";
import equipmentSlice from "./equipmentSlice";
import errorsSlice from "./errorsSlice";
import facilitiesSlice from "./facilitiesSlice";
import facilitySlice from "./facilitySlice";
import matrixSlice from "./matrixSlice";
import methodSlice from "./methodSlice";
import methodsSlice from "./methodsSlice";
import optionsSlice from "./optionsSlice";

const store = configureStore({
  reducer: {
    common: commonSlice,
    options: optionsSlice,
    blocks: blocksSlice,
    connections: connectionsSlice,
    facilities: facilitiesSlice,
    facility: facilitySlice,
    matrix: matrixSlice,
    equipment: equipmentSlice,
    methods: methodsSlice,
    method: methodSlice,
    errors: errorsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
