import React from 'react';
import { useNavigate } from 'react-router-dom';

import './style.scss';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/");
  };

  return (
    <div className="error-page__container">
      <div className="error-page__content">
        <div className="title error-page__title">Страница не найдена</div>
        <button
          className="error-page__link"
          onClick={redirect}
        >
          На главную
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
