import React from "react";
import { useSelector } from "react-redux";

import { selectSelectedBlock } from "../../../../store/blocksSlice";
import InputPoint from "./InputPoint";

import "./style.scss";

const InputPoints: React.FC = () => {
  const selectedBlock = useSelector(selectSelectedBlock);

  return (
    <>
      {selectedBlock?.instance?.input_connection_points?.map((item) => (
        <InputPoint connectionPoint={item} />
      ))}
    </>
  );
};

export default InputPoints;
