import * as React from "react";
import { useSelector } from "react-redux";

import ApiErrorUI from "../../components/ApiErrorUI/apiError";
import Layout from "../../components/DatabasePageLayout/layout";
import DialogWindow from "../../components/DialogWindow";
import { selectApiError } from "../../store/commonSlice";
import EquipmentTree from "./components/EquipmentTree";
import TablePanel from "./components/TablePanel";
import useCleanup from "./hooks/use-cleanup";
import useTableHeads from "./hooks/use-table-heads";

import "./style.scss";

const Equipment: React.FC = () => {
  useTableHeads();
  useCleanup();
  const apiError = useSelector(selectApiError);

  return (
    <>
      <DialogWindow />
      {apiError?.isError ? (
        <ApiErrorUI />
      ) : (
        <Layout pageTitle="База оборудования">
          <div className="blocks__container">
            <EquipmentTree />
            <TablePanel />
          </div>
        </Layout>
      )}
    </>
  );
};

export default Equipment;
