import React from "react";
import { useSelector } from "react-redux";

import { components } from "../../generated/apiTypes";
import CloseIcon from "../../images/icn-close";
import { selectShowDialog } from "../../store/commonSlice";
import { selectVersion } from "../../store/facilitySlice";
import Button from "../Button";
import BlockTypeOptions from "./components/BlockTypeOptions";
import FacilityTypeOptions from "./components/FacilityTypeOptions";
import VersionOptions from "./components/VersionOptions";

import "./style.scss";

interface IChoiceWindowProps {
  closeWindow(): void;
  setOption(
    id: number | components["schemas"]["FacilityCreate"]["schema_type_id"],
    name?: string
  ): void;
  inProgress: boolean;
}

const ChoiceWindow: React.FC<IChoiceWindowProps> = ({
  setOption,
  closeWindow,
  inProgress,
}) => {
  const showDialog = useSelector(selectShowDialog);
  const version = useSelector(selectVersion);
  const [optionId, setOptionId] = React.useState<
    number | components["schemas"]["FacilityCreate"]["schema_type_id"]
  >();
  const [templateOption, setTemplateOption] =
    React.useState<components["schemas"]["FacilityCreate"]>();

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === "Enter") {
        onSetOption();
      }
    };
    document.addEventListener("keydown", onKeyDownHandler as any);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler as any);
    };
  }, []);

  React.useEffect(() => {
    if (version?.id && showDialog.objectType === "VERSION")
      setOptionId(version.id);
  }, [version?.id]);

  const onSetOption = () => {
    if (optionId) setOption(optionId);
    else if (templateOption)
      setOption(templateOption.schema_type_id, templateOption.name);
  };

  const titleByObjectType = () => {
    switch (showDialog?.objectType) {
      case "FACILITY":
        return "Выберите тип объекта";
      case "BLOCK":
        return "Добавление блоков";
      case "VERSION":
        return "Версия объекта";
      default:
        return "";
    }
  };

  const buttonTextByObjectType = () => {
    switch (showDialog?.objectType) {
      case "FACILITY":
        return "Создать объект";
      case "BLOCK":
        return "Добавить";
      case "VERSION":
        return "Редактировать";
      default:
        return "";
    }
  };

  return (
    <div className="dialog-window__background">
      <div className="dialog-window__container choice-window__container">
        <div className="dialog-window__title-wrapper">
          <button
            type="button"
            className="dialog-window__close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window__title">{titleByObjectType()}</div>
        </div>
        <div className="dialog-window__wrapper">
          {showDialog.objectType === "VERSION" && (
            <VersionOptions
              optionId={optionId as number}
              setOption={setOptionId}
            />
          )}
          {showDialog.objectType === "BLOCK" && (
            <BlockTypeOptions
              optionId={optionId as number}
              setOption={setOptionId}
            />
          )}
          {showDialog.objectType === "FACILITY" && (
            <FacilityTypeOptions
              option={templateOption}
              setOption={setTemplateOption}
            />
          )}
          <div className="dialog-window__buttons-wrapper">
            <Button
              className="dialog-window__button"
              mode="secondary"
              onClick={closeWindow}
            >
              Отмена
            </Button>
            <Button
              className="dialog-window__button"
              onClick={onSetOption}
              disabled={inProgress}
            >
              {inProgress
                ? "Подождите..."
                : buttonTextByObjectType()}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoiceWindow;
