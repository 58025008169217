import React from 'react';
import { useSelector } from 'react-redux';

import CloseIcon  from "../../images/icn-close";
import { selectShowDialog } from '../../store/commonSlice';
import Button from '../Button';

import './style.scss';

interface IDeleteConfirmationWindowProps {
  closeWindow(): void;
  deleteObject(): void;
  inProgress: boolean;
}

const DeleteConfirmationWindow: React.FC<IDeleteConfirmationWindowProps> = ({
  deleteObject,
  closeWindow,
  inProgress,
}) => {
  const showDialog = useSelector(selectShowDialog);

  const onDeleteClick = () => {
    deleteObject();
  };

  React.useEffect(() => {
    const onKeyDownHandler = (e: React.KeyboardEvent) => {
      const { key } = e;
      if (key === "Enter") {
        onDeleteClick();
      }
    };
    document.addEventListener("keydown", onKeyDownHandler as any);
    return () => {
      document.removeEventListener("keydown", onKeyDownHandler as any);
    };
  }, []);

  const titleByObjectType = () => {
    switch (showDialog?.objectType) {
      case "FACILITY":
        return "объекта обустройства";
      case "BLOCK":
      case "MATRIX_BLOCK":
        return "блока";
      case "EQUIPMENT_ROW":
        return "строки с оборудованием";
      case "VERSION":
        return "версии";
      case "CONNECTION":
        return "подключения";
      default:
        return "";
    }
  };
  const messageByObjectType = () => {
    switch (showDialog?.objectType) {
      case "FACILITY":
        return "объект обустройства";
      case "BLOCK":
      case "MATRIX_BLOCK":
        return "блок";
      case "EQUIPMENT_ROW":
        return "данную строку";
      case "VERSION":
        return "версию";
      case "CONNECTION":
        return "подключение";
      default:
        return "";
    }
  };
  return (
    <div className="dialog-window__background">
      <div className="dialog-window__container">
        <div className="dialog-window__title-wrapper">
          <button
            type="button"
            className="dialog-window__close-button"
            onClick={closeWindow}
          >
            <CloseIcon />
          </button>
          <div className="dialog-window__title">
            Удаление {titleByObjectType()}
          </div>
        </div>
        <div className="dialog-window__wrapper">
          <div className="dialog-window__message">
            Вы действительно хотите удалить {messageByObjectType()}
            {showDialog?.objectName && ` "${showDialog?.objectName}" `}?
          </div>
          <div className="dialog-window__buttons-wrapper">
            <Button
              className="dialog-window__button"
              mode="secondary"
              onClick={closeWindow}
            >
              Отмена
            </Button>
            <Button
              className="dialog-window__button"
              onClick={onDeleteClick}
              disabled={inProgress}
            >
              {inProgress ? "Подождите..." : "Удалить"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationWindow;
