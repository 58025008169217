import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate} from "react-router-dom";

import update from "immutability-helper";

import { components } from "../../../../generated/apiTypes";
import InfoIcon from '../../../../images/icn-info';
import {updateMatrixBlockOutConnectionPointById, updateOutConnectionPointById} from "../../../../services/apiRequests";
import {
  selectSelectedBlock,
  setSelectedBlockInstance,
  updateBlock,
} from "../../../../store/blocksSlice";
import {
  selectSelectedMatrixBlock,
  setSelectedMatrixBlockInstance,
} from "../../../../store/matrixSlice";
import {setSelectedMethodId, setSelectedMethodInstance} from "../../../../store/methodSlice";

interface IHintProps {
  hint: components["schemas"]["Hint"];
  param:
    | "type"
    | "temperature"
    | "pressure"
    | "molar_flow"
    | "temperature_gidrat"
    | "temperature_parafin";
  blurElement(): void;
  cpId: number;
  matrix?: boolean;
}

const Hint: React.FC<IHintProps> = ({
  hint,
  param,
  blurElement,
  cpId,
  matrix,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedBlock = !matrix
    ? useSelector(selectSelectedBlock)
    : useSelector(selectSelectedMatrixBlock);

  const onChangeValue = () => {
    if (selectedBlock.instance && selectedBlock.id) {
      try {
        if (!matrix) {
          const index = selectedBlock.instance?.out_connection_points.findIndex(
            ({ id }) => cpId === id
          );
          if (index !== undefined && index > -1) {
            const pointsToUpdate = update(
              selectedBlock.instance?.out_connection_points,
              {
                [index]: {
                  $set: {
                    ...selectedBlock.instance?.out_connection_points[index],
                    [param]: hint.name,
                  },
                },
              }
            );
            const objToUpdate = {
              ...selectedBlock.instance,
              out_connection_points: pointsToUpdate,
            };
            dispatch(setSelectedBlockInstance(objToUpdate));
            dispatch(updateBlock(objToUpdate));
          }
        } else {
          const index = selectedBlock.instance?.out_connection_points.findIndex(
            ({ id }) => cpId === id
          );
          if (index !== undefined && index > -1) {
            const pointsToUpdate = update(
              selectedBlock.instance?.out_connection_points,
              {
                [index]: {
                  $set: {
                    ...selectedBlock.instance?.out_connection_points[index],
                    [param]: hint.name,
                  },
                },
              }
            );
            const objToUpdate = {
              ...selectedBlock.instance,
              out_connection_points: pointsToUpdate,
            };
            dispatch(setSelectedMatrixBlockInstance(objToUpdate));
            dispatch(updateBlock(objToUpdate));
          }
        }
        blurElement();
        saveValue();

      } catch (e) {
        //
      }
    }
  };

  const saveValue = async () => {
    if (selectedBlock.id && selectedBlock.instance) {
      try {
        if (!matrix) {
          await updateOutConnectionPointById(
            selectedBlock.id.toString(),
            cpId.toString(),
            {
              [param]: hint.name,
            }
          );
        } else {
          await updateMatrixBlockOutConnectionPointById(cpId.toString(), {
            [param]: hint.name,
          });
        }
      } catch (e) {
        //
      }
    }
  };

  const onInfoClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(setSelectedMethodId(hint.id));
    dispatch(setSelectedMethodInstance(undefined));
    navigate(`/methods`);
  };

  return (
    <button
      id={hint.id.toString()}
      className="hint popup-menu-item"
      onClick={onChangeValue}
    >
      {hint.name}
      <button onClick={onInfoClick}>
        <InfoIcon />
      </button>
    </button>
  );
};

export default Hint;
