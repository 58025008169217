import React from "react";
import { useSelector } from "react-redux";

import classnames from "classnames";

import IconError from "../../../../../images/icn-error";
import IconToggle from "../../../../../images/icn-toggle";
import { selectErrors } from "../../../../../store/errorsSlice";
import { selectMatrix } from "../../../../../store/matrixSlice";

const ErrorsPanel: React.FC = () => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const errors = useSelector(selectErrors);
  const matrix = useSelector(selectMatrix);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const matrixErrors = errors?.find((m) => m.connection_matrix_id === matrix?.id)?.errors;

  return (
    <div className="connections-panel__container">
      <button
        className={classnames("connections-panel__header", { expanded })}
        onClick={toggleExpanded}
      >
        <div className="errors-panel__title">
          <div>ПАНЕЛЬ ОШИБОК</div>
          {matrixErrors && matrixErrors.length > 0 && (
            <div className="errors-panel__errors-count-wrapper">
              <IconError />
              <div className="errors-panel__errors-count">
                {matrixErrors.length}
              </div>
            </div>
          )}
        </div>
        <button className="connections-panel__close-btn">
          <IconToggle className="errors-panel__icn-toggle" />
        </button>
      </button>
      {expanded && !errors && (
        <div
          className={classnames(
            "connections-panel__empty-message errors-panel__empty-message"
          )}
        >
          {"Проверка объекта еще не проводилась"}
        </div>
      )}
      {expanded && errors && !matrixErrors && (
        <div
          className={classnames(
            "connections-panel__empty-message errors-panel__empty-message"
          )}
        >
          {"Ошибок не обнаружено"}
        </div>
      )}
      {expanded && matrixErrors && matrixErrors?.length > 0 && (
        <div className="connections-panel__scrollable">
          {matrixErrors.map((item) => (
            <div className="connection-row__container errors-row__container">
              <div className="errors-row errors-row__element">
                {item.element.object}
                {item.element.property}
              </div>
              <div className="errors-row errors-row__error">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ErrorsPanel;
