import React from "react";
import {useDispatch, useSelector} from "react-redux";

import DeleteIcon from "../../../../images/icn-delete";
import { setShowDialog } from "../../../../store/commonSlice";
import { selectSelectedMatrixBlock } from "../../../../store/matrixSlice";
import useSelectedMatrixBlock from "../../hooks/use-selected-matrix-block";
import ConnectionPoints from "./ConnectionPoints";
import Parameters from "./Parameters";

import "./style.scss";

const BlockDetails: React.FC = () => {
  useSelectedMatrixBlock();
  const dispatch = useDispatch();
  const selectedBlock = useSelector(selectSelectedMatrixBlock);
  const titleRef = React.useRef<HTMLDivElement>(null);
  const scrollableRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.style.maxHeight = `calc(100vh - 52px - ${titleRef.current?.clientHeight}px)`;
    }
  }, [titleRef.current?.clientHeight, scrollableRef.current]);

  const onDeleteClick = () => {
    dispatch(
      setShowDialog({
        objectName: selectedBlock?.instance?.name,
        objectType: "MATRIX_BLOCK",
        dialogType: "DELETE_CONFIRM",
        objectId: selectedBlock.id?.toString(),
      })
    );
  };

  return selectedBlock?.instance ? (
    <div className="facility-block-details__container">
      <div className="facility-block-details__title-row" ref={titleRef}>
        <div className="facility-block-details__title">
          {selectedBlock?.instance?.name}
        </div>
        <button
          className="facility-block-details__title-btn"
          onClick={onDeleteClick}
          disabled={selectedBlock?.index === 0}
        >
          {selectedBlock?.index !== 0 && <DeleteIcon />}
        </button>
      </div>
      <div
        className="facility-block-details__scrollable-panel"
        ref={scrollableRef}
      >
        <Parameters />
        <ConnectionPoints />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BlockDetails;
