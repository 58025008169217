import React from "react";
import { useDispatch, useSelector } from "react-redux";

import IconPlus from "../../../images/icn-plus";
import { copyVersion } from "../../../services/apiRequests";
import { selectFacility, setFacility } from "../../../store/facilitySlice";
import VersionOption from "./VersionOption";

import "./style.scss";

interface IVersionOptionsProps {
  optionId?: number;
  setOption(id: number): void;
}

const VersionOptions: React.FC<IVersionOptionsProps> = ({
  optionId,
  setOption,
}) => {
  const dispatch = useDispatch();
  const facility = useSelector(selectFacility);
  const scrollableRef = React.useRef(null);

  React.useEffect(() => {
    if (facility?.versions[facility?.versions.length - 1].id) {
      setOption(facility?.versions[facility?.versions.length - 1].id);
    }
  }, [facility?.versions.length]);

  const setValue = (id: string) => {
    const optionId = parseInt(id);
    if (optionId) setOption(optionId);
  };

  const onCopyClick = async (id?: number) => {
    try {
      const versionToCopy = facility?.versions?.find((item) => item.id === id);
      if (id && versionToCopy) {
        const response = await copyVersion(id.toString(), versionToCopy);
        if (response.data && facility) {
          dispatch(
            setFacility({
              ...facility,
              versions: [...facility.versions, response?.data],
            })
          );
        }
      }
    } catch (e) {
      //
    }
  };

  return (
    <>
      <div className="option__scrollable" ref={scrollableRef}>
        {facility?.versions?.map((item, index, arr) => (
          <VersionOption
            scrollableRef={scrollableRef}
            setValue={setValue}
            optionId={optionId}
            id={item.id}
            name={item.name}
            onCopyClick={onCopyClick}
            singleVersion={arr.length === 1}
          />
        ))}
      </div>
      <button
        className="control-row__add-btn option__add-btn"
        onClick={() => onCopyClick(optionId)}
      >
        <IconPlus />
        Копия выбранной версии
      </button>
    </>
  );
};

export default VersionOptions;
