import * as React from "react";
import {useDispatch, useSelector} from "react-redux";

import classNames from "classnames";

import { components } from "../../../../generated/apiTypes";
import {
  selectAllEquipment,
  selectHeadByCategory,
  selectSelectedClass, setSelectedClass,
} from "../../../../store/equipmentSlice";
import { formattedTableHead } from "./mock";
import TableHead from "./TableHead";
import TableRow from "./TableRow";

import "./style.scss";

const Table: React.FC = () => {
  const dispatch = useDispatch();
  const tableHeadByCategory = useSelector(selectHeadByCategory);
  const selectedClass = useSelector(selectSelectedClass);
  const allEquipment = useSelector(selectAllEquipment);

  React.useEffect(() => {
    if (selectedClass?.id && allEquipment) {
      const equipmentByClass: components["schemas"]["EquipmentItemParams"][] =
        allEquipment.filter(
          (item) => item.equipment_class.id === selectedClass.id
        );
      dispatch(
        setSelectedClass({ ...selectedClass, equipment: equipmentByClass })
      );
    }
  }, [selectedClass?.id, allEquipment]);

  return selectedClass?.id ? (
    tableHeadByCategory[selectedClass?.category]?.column_headers && (
      <div className="equipment-table__wrapper">
        <table className={classNames("")}>
          <TableHead
            columnHeaders={Array.from(
              tableHeadByCategory[selectedClass?.category]?.column_headers
            )}
            tableHead={
              tableHeadByCategory[selectedClass?.category]?.column_headers
            }
            tableHeadFormatted={formattedTableHead}
          />
          <tbody>
            {selectedClass?.equipment &&
              selectedClass?.equipment?.length > 0 &&
              selectedClass?.category &&
              selectedClass.equipment?.map((item) => {
                return (
                  <TableRow
                    equipmentId={item.id}
                    columnHeaders={Array.from(
                      tableHeadByCategory[selectedClass?.category]
                        ?.column_headers
                    )}
                    valuesRow={item.params}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    )
  ) : (
    <></>
  );
};

export default Table;
