import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import update from "immutability-helper";

import { components } from "../generated/apiTypes";
import { extractObjectByCategory } from "../utils/object";
import { initialState } from "./initialState";
import { RootState } from "./store";
import { ArrayElement, IEquipmentSlice } from "./types";

const equipmentInitialState = initialState as IEquipmentSlice;

export const equipmentSlice = createSlice({
  name: "equipment",
  initialState: {
    tableHeads: equipmentInitialState.tableHeads,
    equipmentClasses: equipmentInitialState.equipmentClasses,
    allEquipment: equipmentInitialState.allEquipment,
    selectedClass: equipmentInitialState.selectedClass,
    parameterToSave: equipmentInitialState.parameterToSave,
  },
  reducers: {
    setTableHeads: (
      state,
      action: PayloadAction<IEquipmentSlice["tableHeads"]>
    ) => {
      state.tableHeads = action.payload;
    },
    setEquipmentClasses: (
      state,
      action: PayloadAction<IEquipmentSlice["equipmentClasses"]>
    ) => {
      state.equipmentClasses = action.payload;
    },
    setAllEquipment: (
      state,
      action: PayloadAction<IEquipmentSlice["allEquipment"]>
    ) => {
      state.allEquipment = action.payload;
    },
    setSelectedClass: (
      state,
      action: PayloadAction<IEquipmentSlice["selectedClass"]>
    ) => {
      state.selectedClass = action.payload;
    },
    deleteEquipment: (
      state,
      action: PayloadAction<components["schemas"]["EquipmentItemParams"]["id"]>
    ) => {
      const index = state.allEquipment?.findIndex(
        ({ id }) => action.payload === id
      );
      if (index !== undefined && index > -1) {
        state.allEquipment = update(state.allEquipment, {
          $splice: [[index, 1]],
        });
      }
    },
    updateEquipment: (
      state,
      action: PayloadAction<components["schemas"]["EquipmentItemParams"]>
    ) => {
      const index = state.allEquipment?.findIndex(
        ({ id }) => action.payload.id === id
      );
      if (index !== undefined && index > -1) {
        state.allEquipment = update(state.allEquipment, {
          [index]: { $set: action.payload },
        });
      }
    },
    updateSelectedClassEquipment: (
      state,
      action: PayloadAction<components["schemas"]["EquipmentItemParams"]>
    ) => {
      const index = state.selectedClass?.equipment?.findIndex(
        ({ id }) => action.payload.id === id
      );
      if (index !== undefined && index > -1) {
        state.selectedClass = update(state.selectedClass, {
          equipment: {
            [index]: { $set: action.payload },
          },
        });
      }
    },
    setParameterToSave: (
      state,
      action: PayloadAction<IEquipmentSlice["parameterToSave"]>
    ) => {
      state.parameterToSave = action.payload;
    },
    resetFacilitySliceState: (state) => {
      state.tableHeads = equipmentInitialState.tableHeads;
      state.equipmentClasses = equipmentInitialState.equipmentClasses;
      state.allEquipment = equipmentInitialState.allEquipment;
      state.selectedClass = equipmentInitialState.selectedClass;
      state.parameterToSave = equipmentInitialState.parameterToSave;
    },
  },
});

export const {
  setTableHeads,
  setEquipmentClasses,
  setAllEquipment,
  setSelectedClass,
  deleteEquipment,
  updateEquipment,
  updateSelectedClassEquipment,
  setParameterToSave,
} = equipmentSlice.actions;

const selectSelf = (state: RootState) => state.equipment;

type SelectHeadByCategory = {
  [category: string]: ArrayElement<IEquipmentSlice["tableHeads"]>;
};

export const selectHeadByCategory = createSelector(selectSelf, (equipment) =>
  equipment.tableHeads?.reduce<SelectHeadByCategory>(
    extractObjectByCategory,
    {}
  )
);

export const selectEquipmentClasses = createSelector(
  selectSelf,
  (equipment) => equipment.equipmentClasses
);

export const selectAllEquipment = createSelector(
  selectSelf,
  (equipment) => equipment.allEquipment
);

export const selectSelectedClass = createSelector(
  selectSelf,
  (equipment) => equipment.selectedClass
);

export const selectParameterToSave = createSelector(
  selectSelf,
  (equipment) => equipment.parameterToSave
);

export default equipmentSlice.reducer;
