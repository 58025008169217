import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../../../components/Button";
import SearchInput from "../../../../components/SearchInput";
import { components } from "../../../../generated/apiTypes";
import { setShowDialog } from "../../../../store/commonSlice";
import {
  selectMatrixBlocks,
  selectSelectedMatrixBlock,
  setSelectedMatrixBlock,
} from "../../../../store/matrixSlice";
import useMatrixBlocks from "../../hooks/use-matrix-blocks";
import Block from "./Block";

import "./style.scss";

const BlocksTree: React.FC = () => {
  const dispatch = useDispatch();
  useMatrixBlocks();
  const blocks = useSelector(selectMatrixBlocks);
  const selectedBlock = useSelector(selectSelectedMatrixBlock);

  const [searchInputValue, setSearchInputValue] = React.useState<string>("");
  const [filteredBlocks, setFilteredBlocks] = React.useState<
      components["schemas"]["Block"][]
      >([]);

  React.useEffect(() => {
    if (blocks) setFilteredBlocks(blocks);
  }, [blocks]);

  const onChangeSearchInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInputValue(e.target.value);
    if (blocks) {
      const filteredList = blocks.filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      if (!filteredList.find((item) => item.id === selectedBlock.id)) {
        dispatch(setSelectedMatrixBlock({}));
      }
      setFilteredBlocks(filteredList);
    }
  };

  const onCreateBtnClick = async () => {
    dispatch(setShowDialog({ dialogType: "CHOICE", objectType: "BLOCK" }));
  };

  return (
    <div className="blocks-tree__container">
      <div>
        <div className="blocks-tree__search-wrapper">
          <SearchInput
            onInput={onChangeSearchInputValue}
            value={searchInputValue}
            placeholder="Блок"
            containerClassName="blocks-tree__search-input-container"
          />
        </div>
        <div className="blocks-tree__blocks">
          {filteredBlocks &&
            filteredBlocks?.length > 0 &&
            filteredBlocks?.map((item, index, arr) => (
              <Block
                id={item.id}
                name={item.name}
                index={index}
                length={arr.length}
                locked={searchInputValue !== ''}
              />
            ))}
          {blocks && blocks?.length === 0 && !searchInputValue && (
            <div className="blocks-tree__blocks-empty-message">
              Не добавлен ни один блок
            </div>
          )}
          {filteredBlocks &&
            filteredBlocks?.length === 0 &&
            searchInputValue && (
              <div className="blocks-tree__blocks-empty-message">
                Не найдены блоки соответствующие данному запросу
              </div>
            )}
        </div>
      </div>
      <div className="blocks-tree__btn-wrapper">
        <Button className="blocks-tree__btn" onClick={onCreateBtnClick}>
          Добавить блок
        </Button>
      </div>
    </div>
  );
};

export default BlocksTree;
