import React from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";

import { selectConnectionPoint } from "../../../../../store/connectionsSlice";

const LabelsRow: React.FC = () => {
  const selectedConnectionPoint = useSelector(selectConnectionPoint);

  return (
    <div
      className={classNames(
        "labels-row__container",
        selectedConnectionPoint?.type === "IN" && "input-row-container"
      )}
    >
      {selectedConnectionPoint?.type === "OUT" && (
        <div className="block__control-arrows connection-row__arrows invisible labels-row__arrows">
          <div className="block__control-arrow up-arrow" />
          <div className="block__control-arrow" />
        </div>
      )}
      <div
        className={classNames(
          "connection-row__priority-order invisible",
          selectedConnectionPoint?.type === "IN" && "input-point-order"
        )}
      />
      <div className="connection-row__select-container">
        <span className="labels-row__label">Блок подключения</span>
      </div>
      <div className="connection-row__select-container">
        <span className="labels-row__label">Точка подключения</span>
      </div>
      <div className="connection-row__select-container">
        <span className="labels-row__label">Условие</span>
      </div>
      <div className="facility__delete-btn labels-row__btn invisible" />
    </div>
  );
};

export default LabelsRow;
