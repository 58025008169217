import * as React from "react";

const Icon = (props: any) => {
  return (
    <svg
      width={16}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.07583 0.526506C7.48657 -0.175501 8.51343 -0.175502 8.92417 0.526505L15.8554 12.3729C16.2662 13.0749 15.7528 13.9524 14.9313 13.9524H1.06873C0.247248 13.9524 -0.266181 13.0749 0.144561 12.3729L7.07583 0.526506Z"
        fill="#F15050"
      />
      <rect
        x="7"
        y="2.98981"
        width="2"
        height="5.97955"
        rx="1"
        fill="#242425"
      />
      <rect
        x="7"
        y="9.966"
        width="2"
        height="1.99316"
        rx="0.996582"
        fill="#242425"
      />
    </svg>
  );
};

export default Icon;
