import * as React from 'react';

import classnames from 'classnames';

import Icons from './icons';

import './style.scss';

interface ISearchInput extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  clearInputValue?(): void;
  containerClassName?: string;
}

const SearchInput: React.FC<ISearchInput> = ({
  className,
  containerClassName,
  clearInputValue,
  ...props
}) => {
  const onClearButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (clearInputValue) clearInputValue();
  };

  return (
    <div className={classnames("search-input__container", containerClassName)}>
      <input
        {...props}
        className={classnames("search-input normal-text", className)}
        spellCheck={false}
      />
      <div className="search-input__search-icon">
        <Icons.SearchIcon />
      </div>
      <button
        className="search-input__clear-button"
        onMouseDown={onClearButtonClick}
        type="button"
      >
        <Icons.ClearIcon />
      </button>
    </div>
  );
};

export default SearchInput;
